import { defineStore } from "pinia";

interface IToken{
    access: string;
    refresh: string;
    isAuth:boolean;
    errorMsg:string;
    route:string;
}

const userTokenStore = defineStore({
    id:"token",
    state: ():IToken => ({
         access: '',
         refresh: '',
         isAuth: false, 
         errorMsg: '',
         route:'',
        }),
    getters: {
      getIsAuth: (state) => state.isAuth,
      getRefresh: (state) => state.refresh,
      getAccess: (state) => state.access,
      getErrorMsg: (state) => state.isAuth ? '' : state.errorMsg,
      getRoute: (state) =>   state.isAuth ? state.route : '',     
    },
    actions: {
      setAuth(b: boolean) {
        this.isAuth = b        
      },
      patch(token: any){
        this.access = token.access;
        this.refresh = token.refresh;
        this.isAuth = true;
        this.errorMsg = '';
        this.route = token.route;
      },
      clearToken(errorMessage:string){
        this.access = '';
        this.refresh = '';
        this.isAuth = false;
        this.errorMsg = errorMessage;
        this.route = '';
      },
    },
  })

  export {IToken, userTokenStore }