<template>
    <ModalMessage
    v-if="layout"
        :icon="icon"
        :title="layout.labels.title"
        :description="layout.labels.lines"
        :button="{textContent: layout.labels.button, danger: false}"
        @button-click="close"
    />
</template>


<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import { IPageLayout, getComputedPageLayout } from '@/helpers/api';
import { getIcon } from '@/helpers/api';
import ModalMessage from '@/globals/components/ModalMessage.vue';
import { modalController } from '@ionic/vue';


interface _1611 extends IPageLayout {
    labels: {
        title: string,
        lines: Array<string>,
        button: string,
    }
}

const icon = ref<string>()
const layout = getComputedPageLayout<_1611>('1611')
onBeforeMount(async () => {
    icon.value = await getIcon("1611", 'icon')
})

function close(){
    return modalController.dismiss(null, 'cancel')
}

</script>

<style scoped>

</style>
