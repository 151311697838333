import { IWorksheet } from "@/modules/a.worksheets/stores/worksheetDataStore";
import { IWorksheetAnswerStore } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import {
  stringify,
  parse,
  inspect,
} from "@/persistentStorage/wks_storage/customStringify";
import WksStorageService from "./wksStorageService";
import { ref } from "vue";
import hash from "hash-it";
import {
  IWorksheetDataTree,
  IPreprocessedWorksheetDataTree,
  WrappedWorksheetData,
} from "./model";
import { spinnerManager } from "@/globals/pages/spinnerControl";
import { deleteFile } from "@/helpers/filesReadWrite";

/**
 * Class WorksheetProcessor
 * This class is responsible for processing worksheets, including handling
 * IFile objects within them and stringifying worksheets for storage.
 */
class WorksheetProcessor {
  /**
   * Reconstructs the worksheets from their stringified forms.
   * @param {Map<string, string>} wksMap - A map of worksheet UUIDs to their stringified forms.
   * @returns {Promise<Map<string, IWorksheet>>} A promise that resolves to a map of reconstructed worksheet objects.
   */
  async reconstructWorksheets(
    wksMap: Map<string, string>
  ): Promise<Map<string, WrappedWorksheetData>> {
    const worksheets = new Map<string, WrappedWorksheetData>();
    for (const [worksheetUUID, stringifiedWorksheet] of wksMap) {
      const worksheet: WrappedWorksheetData = await parse(stringifiedWorksheet); // Assuming stringify was used for the worksheet
      worksheets.set(worksheetUUID, worksheet);
    }
    return worksheets;
  }

  /**
   * Processes a worksheet by stringifying it.
   * @param {IWorksheet} worksheet - The worksheet to process.
   * @returns {Promise<string>} A promise that resolves to the stringified worksheet.
   */
  async processWorksheetFiles(
    worksheet: WrappedWorksheetData,
    isDataLocale: boolean = false
  ): Promise<string> {
    const stringifiedWorksheet = await stringify(worksheet, { isDataLocale });
    return stringifiedWorksheet;
  }
}

/**
 * Class DataTreeManager
 * Manages the operations related to the data tree of worksheets, including
 * loading, updating, and resetting the data.
 */
class DataTreeManager {
  // # Dependencies
  private worksheetProcessor: WorksheetProcessor;
  private storage: WksStorageService;
  public stateHash = ref<number>();

  // # Data
  private _dataTree: IWorksheetDataTree | undefined;
  get dataTree(): IWorksheetDataTree | undefined {
    return this._dataTree;
  }
  set dataTree(dataTree: IWorksheetDataTree | undefined) {
    this._dataTree = dataTree;
    this.setStateHash(dataTree);
  }

  constructor(name: string, storageName: string) {
    this.storage = new WksStorageService(name, storageName);
    this.worksheetProcessor = new WorksheetProcessor();
  }

  // # Public Methods

  public wksLength(): number {
    const dataTree = this._dataTree;
    if (!dataTree) return 0;
    const keys = Array.from(dataTree.worksheets.keys());
    return keys.length;
  }

  /**
   * Loads the worksheet data from the local storage and returns it
   */
  public async loadAndGet(mountKey: string): Promise<IWorksheetDataTree> {
    this.dataTree = await this.retrieveDataTree(mountKey);
    return this.dataTree;
  }

  /**
   * Loads only the worksheet data from the local storage when the current worksheet data is undefined, otherwise it returns the present data
   */
  public async get(mountKey: string): Promise<IWorksheetDataTree> {
    if (this.dataTree) {
      return this.dataTree;
    }
    this.dataTree = await this.retrieveDataTree(mountKey);
    return this.dataTree;
  }

  /**
   * Resets the data tree in local storage to a fresh, empty state.
   */
  public async resetDataTree(mountKey: string): Promise<void> {
    const freshDataTree: IWorksheetDataTree = {
      courses: new Map(),
      chapters: new Map(),
      subchapters: new Map(),
      worksheets: new Map(),
    };
    const dataTreeString = JSON.stringify(freshDataTree, this.replacer);
    await this.storage.set(mountKey, dataTreeString);
    this.dataTree = freshDataTree;
  }

  /**
   * Adds a worksheet to the data tree under specified UUIDs **(persistent)**.
   * @param {string} courseUUID - UUID of the course.
   * @param {string} chapterUUID - UUID of the chapter.
   * @param {string} subchapterUUID - UUID of the subchapter.
   * @param {IWorksheet} worksheet - The worksheet object to add.
   */
  public async addWorksheet(
    mountKey: string,
    courseUUID: string,
    courseTitle: string,
    chapterUUID: string,
    chapterTitle: string,
    subchapterUUID: string,
    subchapterTitle: string,
    worksheet: IWorksheet,
    answers: IWorksheetAnswerStore,
    uuid: string,
    solved_ratio: number
  ): Promise<void> {
    // Retrieve the current data tree
    const dataTree = await this.get(mountKey);

    // Update the tree with the new worksheet
    if (!dataTree.courses.has(courseUUID)) {
      dataTree.courses.set(courseUUID, {
        uuid: courseUUID,
        title: courseTitle,
        chapters: [],
      });
    }
    if (!dataTree.chapters.has(chapterUUID)) {
      dataTree.courses.get(courseUUID)?.chapters.push(chapterUUID);
      dataTree.chapters.set(chapterUUID, {
        uuid: chapterUUID,
        title: chapterTitle,
        subchapters: [],
      });
    }
    if (!dataTree.subchapters.has(subchapterUUID)) {
      dataTree.chapters.get(chapterUUID)?.subchapters.push(subchapterUUID);
      dataTree.subchapters.set(subchapterUUID, {
        uuid: subchapterUUID,
        title: subchapterTitle,
        worksheets: [],
      });
    }
    const subtree = dataTree.subchapters.get(subchapterUUID);
    if (!subtree) {
      throw new Error("Subchapter not present but should be");
    }

    if (!dataTree.worksheets.has(worksheet.uuid)) {
      subtree.worksheets.push(worksheet.uuid);

      const stringifiedWorksheet = await stringify(worksheet, {
        isDataLocale: false,
      });
      const parsedWorksheet: IWorksheet = JSON.parse(stringifiedWorksheet);

      const stringifiedAnswers = await stringify(answers, {
        isDataLocale: false,
      });
      const parsedAnswers: IWorksheetAnswerStore =
        JSON.parse(stringifiedAnswers);

      dataTree.worksheets.set(worksheet.uuid, {
        wks: parsedWorksheet,
        answers: parsedAnswers,
        uuid,
        solved_ratio,
      });
      // TODO: part of the data tree can contain uri's to already local data and part can have uri's from the server
      await this.updateDataTree(mountKey, dataTree, false);
    }
  }

  public getWrappedWorksheetData(
    worksheetUUID: string
  ): WrappedWorksheetData | undefined {
    return this.dataTree?.worksheets.get(worksheetUUID);
  }
  public getStatusUUID(wksUUID: string): string | undefined {
    if (this.dataTree === undefined) return undefined;

    for (const [key, wrappedWks] of this.dataTree.worksheets.entries()) {
      if (wrappedWks.uuid === wksUUID) return key;
    }
    return undefined;
  }

  public async deleteWorksheet(
    mountKey: string,
    courseUUID: string,
    chapterUUID: string,
    subchapterUUID: string,
    statusUUID: string
  ): Promise<void> {
    if (this.dataTree === undefined) return;
    spinnerManager.registerTask("deletingWorksheet");
    const dataTree = this.dataTree;

    // Remove the worksheet
    if (dataTree.worksheets.has(statusUUID)) {
      const wks = dataTree.worksheets.get(statusUUID);

      // We now collect all the files and iFiles in the worksheet to delete them from the local storage
      const uris: string[] = [];
      await inspect(wks, {
        onIFile: async (file) => {
          // const filepath = file.filepath.slice(6);
          uris.push(file.filepath);
        },
      });
      for (let i = 0; i < uris.length; i++) {
        await deleteFile(uris[i]);
      }

      dataTree.worksheets.delete(statusUUID);

      const subchapter = dataTree.subchapters.get(subchapterUUID);
      if (subchapter) {
        // Remove the worksheet UUID from the subchapter
        const index = subchapter.worksheets.indexOf(statusUUID);
        if (index > -1) {
          subchapter.worksheets.splice(index, 1);
          // If the subchapter is empty, remove it
          if (subchapter.worksheets.length === 0) {
            dataTree.subchapters.delete(subchapterUUID);

            const chapter = dataTree.chapters.get(chapterUUID);
            if (chapter) {
              // Remove the subchapter UUID from the chapter
              const subchapterIndex =
                chapter.subchapters.indexOf(subchapterUUID);
              if (subchapterIndex > -1) {
                chapter.subchapters.splice(subchapterIndex, 1);
                // If the chapter is empty, remove it
                if (chapter.subchapters.length === 0) {
                  dataTree.chapters.delete(chapterUUID);

                  const course = dataTree.courses.get(courseUUID);
                  if (course) {
                    // Remove the chapter UUID from the course
                    const chapterIndex = course.chapters.indexOf(chapterUUID);
                    if (chapterIndex > -1) {
                      course.chapters.splice(chapterIndex, 1);
                      // If the course is empty, remove it
                      if (course.chapters.length === 0) {
                        dataTree.courses.delete(courseUUID);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // Update the data tree after removals
      await this.updateDataTree(mountKey, dataTree, false);
    } else {
      throw new Error("Worksheet does not exist");
    }
    spinnerManager.unregisterTask("deletingWorksheet");
  }

  public async updateWorksheet(mountKey: string, worksheet: IWorksheet) {
    const dataTree = await this.get(mountKey);
    const worksheetData = dataTree.worksheets.get(worksheet.uuid);
    if (worksheetData) {
      worksheetData.wks = worksheet;
      await this.updateDataTree(mountKey, dataTree);
    }
  }

  public async updateAnswers(
    mountKey: string,
    worksheetUUID: string,
    answers: IWorksheetAnswerStore,
    options: {
      solvedRatio?: number;
      preprocess?: boolean;
      isDataLocale?: boolean;
    } = {
      isDataLocale: false,
      preprocess: true,
    }
  ) {
    const dataTree = await this.get(mountKey);
    const worksheetData = dataTree.worksheets.get(worksheetUUID);
    if (worksheetData) {
      if (options?.solvedRatio !== undefined) {
        worksheetData.solved_ratio = options.solvedRatio;
      }
      worksheetData.answers = answers;

      await this.updateDataTree(
        mountKey,
        dataTree,
        options.preprocess,
        options.isDataLocale
      );

    }
  }

  // # Private Methods
  /**
   * Updates the entire worksheet data tree in local storage.
   * @param {IWorksheetDataTree} dataTree - The data tree to be updated in storage.
   */
  async updateDataTree(
    mountKey: string,
    dataTree: IWorksheetDataTree,
    preprocess: boolean = true,
    isDataLocale: boolean = false
  ): Promise<void> {
    const preprocessDataTree = await this.preprocessDataTree(
      dataTree,
      preprocess,
      isDataLocale
    );
    const dataTreeString = JSON.stringify(preprocessDataTree, this.replacer);
    await this.storage.set(mountKey, dataTreeString);
    this.setStateHash(dataTree);
    await this.loadAndGet(mountKey);
  }

  private setStateHash(dataTree: any) {
    const stateHash = hash(dataTree);
    if (this.stateHash.value !== stateHash) {
      this.stateHash.value = stateHash;
    }
  }

  private async preprocessDataTree(
    dataTree: IWorksheetDataTree,
    preprocess: boolean = true, // Preprocess means to use our custom stringify function which handles IFile objects and saves them to local storage
    isDataLocale: boolean = false
  ): Promise<IPreprocessedWorksheetDataTree> {
    // Iterate over the worksheets and process IFile objects
    const stringifiedWorksheets = new Map<string, string>();
    for (const [worksheetUUID, worksheet] of dataTree.worksheets) {
      const sWks = preprocess
        ? await this.worksheetProcessor.processWorksheetFiles(
            worksheet,
            isDataLocale
          )
        : JSON.stringify(worksheet);
      stringifiedWorksheets.set(worksheetUUID, sWks);
    }

    const preprocessedDataTree: IPreprocessedWorksheetDataTree = {
      courses: dataTree.courses,
      chapters: dataTree.chapters,
      subchapters: dataTree.subchapters,
      worksheets: stringifiedWorksheets,
    };
    // Optionally, process other parts of the data tree if they contain IFile objects
    return preprocessedDataTree;
  }

  /**
   * Reconstructs the data tree from the stringified version.
   * @param {string} dataTreeString - The stringified data tree.
   * @returns {Promise<IWorksheetDataTree>} A promise that resolves to the reconstructed data tree.
   */
  private async reconstructDataTree(
    dataTreeString: string
  ): Promise<IWorksheetDataTree> {
    const preprocessedDataTree: IPreprocessedWorksheetDataTree = JSON.parse(
      dataTreeString,
      this.reviver
    );
    // Reconstruct the worksheets
    const worksheets = await this.worksheetProcessor.reconstructWorksheets(
      preprocessedDataTree.worksheets
    );

    // Construct and return the final data tree
    return {
      courses: preprocessedDataTree.courses,
      chapters: preprocessedDataTree.chapters,
      subchapters: preprocessedDataTree.subchapters,
      worksheets: worksheets,
    };
  }

  /**
   * Retrieves the entire worksheet data tree from local storage.
   * @returns {Promise<IWorksheetDataTree>} A promise that resolves to the worksheet data tree.
   */
  private async retrieveDataTree(mountKey: string): Promise<IWorksheetDataTree> {
    const dataTreeString = await this.storage.get(mountKey);
    if (!dataTreeString) {
      return {
        courses: new Map(),
        chapters: new Map(),
        subchapters: new Map(),
        worksheets: new Map(),
      };
    }
    const dataTree = this.reconstructDataTree(dataTreeString);
    return dataTree;
  }

  // # Helper Functions
  /**
   * A custom replacer function for JSON stringification, used to handle Map objects.
   * @param {string} key - The key in the key-value pair being stringified.
   * @param {any} value - The value in the key-value pair being stringified.
   * @returns {any} The appropriately formatted value for stringification.
   */
  private replacer(key: string, value: any) {
    if (value instanceof Map) {
      return Array.from(value.entries());
    }
    return value;
  }
  /**
   * A custom reviver function for JSON parsing, used to properly reconstruct Map objects.
   * @param {string} key - The key in the key-value pair being parsed.
   * @param {any} value - The value in the key-value pair being parsed.
   * @returns {any} The appropriately reconstructed value.
   */
  private reviver(key: string, value: any) {
    if (Array.isArray(value)) {
      // Check if every element in the array is also an array (key-value pair)
      if (value.every((item) => Array.isArray(item) && item.length === 2)) {
        return new Map(value);
      }
    }
    return value;
  }
}

export default new DataTreeManager("wks_db", "wks_db_store");
