
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
// helper function
const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
   reader.readAsDataURL(blob);
  });


const writeImageFile = async (filename:string, base64Data:any) => {
    const uri = await Filesystem.writeFile({
      path: filename,
      data: base64Data,
      directory: Directory.Data,
    });
    return uri;
  };
  
const writeUTFFile = async (filename:string, data:string) => {
    try {
    await Filesystem.writeFile({
      path: filename,
      data: data,
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    })
} catch (error){
    console.log("ERROR-WRITING-SVG", error);
}
  };

const readImageFile = async (filename:string) => {
       const data = await Filesystem.readFile({
          path: filename,
          directory: Directory.Data,
       })
       return data;
};
const readUTFFile = async (filename:string) => {
    const data = await Filesystem.readFile({
        path: filename,
        directory: Directory.Data,
        encoding: Encoding.UTF8
     })
     return data   
}  

  async function createFolder(folderName:string){
       await Filesystem.mkdir({
        directory: Directory.Data,
        path: folderName,
        recursive: true
       }) 

  }


  async function downloadWriteFile(filename:string, blob:any){
    //const fileExt = url0.split('.').pop();
    const tp = blob.type;
    const base64Data = await convertBlobToBase64(blob);
    if (tp === "image/svg+xml") {
        //data = await blob.text();
        //return await writeUTFFile(filename, data);
        return await writeImageFile(filename, base64Data);
    }
    if (tp === "image/png") {
      //const base64Data = await convertBlobToBase64(blob);        
      return await writeImageFile(filename, base64Data);
  }
}

async function getDir( path:string ){
    const uri = await Filesystem.readdir({
    path:path,
    directory:Directory.Data})
    return uri.files;
    }

//--------------------------------
// NOT WORKING
async function removeFolder(folderName:string, recursive:boolean){
    console.log("REMOVE FOLDER", folderName);
    try {
    const uri = await Filesystem.rmdir({
        path:folderName,
        directory: Directory.Data,
        recursive:recursive
    }
    )
    console.log("REMOVE FOLDER OK", uri);
    } catch {
        console.log("REMOVE FOLDER ERROR");
    }

}
//--------------------------------







  export { convertBlobToBase64, writeImageFile, readImageFile, readUTFFile, createFolder, downloadWriteFile, removeFolder, getDir }
