<template>
    <PopUpMask
        :show-arrow="showArrow"
    >  
        <template #icon>
            <img :src="icon" />
        </template>

        <template #title>
            <h2 id="title"> {{title}} </h2>
        </template>

        <template #description> 
            <div class="description-wrapper">
                
                <span v-for="(line, key) in description"
                        :key="key"
                        v-html="line"
                >
                </span>
            </div>
        </template>

        <template #button>
            <IonButton
                :style="buttonColor" 
                height="2.575241849rem"
                @click="emit('button-click')"
                > 
            {{ button.textContent }}
            </IonButton>
        </template>
    </PopUpMask>

</template>


<script setup lang="ts">
import { PropType, computed }from 'vue'
import PopUpMask from '@/modules/a.worksheets/views/components/feedback/PopUpMask.vue'
import { IonButton } from '@ionic/vue';


const props = defineProps({
    icon: String,
    title: {
        type: String,
        required: true    
    },
    description: {
        type: Object as PropType<Array<string>>
    },

    button: {
        type: Object as PropType<{textContent: string, danger: boolean, router?: string}>,
        required:true
    },
    showArrow : {
        type: Boolean, default: true
    }
})
const emit = defineEmits(['button-click'])

const buttonColor = computed(
    () => props.button.danger ? '--background: var(--milage-alert-color)' : '--background: var(--milage-color-primary)'
)

</script>

<style scoped>
ion-content::part(background){
    background-color: transparent;
}
#title{
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    text-transform: var(--title-font-transform);
    font-weight: var(--title-font-weight);
    text-align: center;
}
img{
    width: 100%;
}
.description-wrapper{
    font-family: var(--description-font-family);
    font-size: var(--description-font-size);
    text-transform: var(--description-font-transform);
    font-weight: var(--description-font-weight); 
    color: var(--milage-color-default-body);
}
.description-wrapper span{
    display: block;
    text-align: center;
}

ion-button{
    width: 100%;
    min-height: 2rem;
}

</style>