import axios from "axios";
import pinia from "@/plugins/piniaStore";

import { useDeviceStatus } from "@/stores/deviceStatus";

import { labelsDataDB } from "@/persistentStorage/appInterface";


import { useAccountDataUser, IUserData } from "@/modules/5.account/stores/accountDataStore"; 

import { piniaStorageDB } from "@/persistentStorage/piniaStorageDB";

import { useWorksheetData } from "@/modules/a.worksheets/stores/worksheetDataStore";
import { useUserAnswersData } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";

import {usePageLayouts, useFonts} from "@/stores/pageLayoutsStore";
import {userTokenStore} from "@/stores/tokenStore";
import { urls } from "@/stores/urls";

import { iconsLocalFiles } from "@/persistentStorage/iconsLocalFiles";
    
import { getPlatforms } from "@ionic/vue";

//Standard user to get app data - pagelayouts and icons - from backend before user get login
const system = {email:"system@milage.ualg.pt", password:"38e87d32-ae50-11ed-afa1-0242ac120002", language:'EN', deviceInfo:{}, platform:""};


//-----------------------------------------------

async function systemDefinitions(){
    const deviceStatus = useDeviceStatus(pinia);
    await deviceStatus.refresh();
    system.deviceInfo = deviceStatus.deviceInfo;
    system.platform = deviceStatus.platform;
}

//----------------------------------------------------------
// open or create local DBs (localstorage)
async function openCreateLocalDBs(){
    await Promise.all([
        labelsDataDB,
        piniaStorageDB,
        iconsLocalFiles,
      ]).then((values) => {
        console.log("create/open databases", values);
      });
}
//-----------------------------------------------------------
// Create userAccount pinia store
async function createPiniaStores(){
    // const deviceStatus = useDeviceStatus(pinia);
    // const accountData = useAccountDataUser(pinia);
    // const wksData = useWorksheetData(pinia);
    // const answerData = useUserAnswersData(pinia);
    // const pL = usePageLayouts(pinia)
    // const fnt = useFonts(pinia)
    // const x = userTokenStore(pinia)
    console.log("")
}

//-----------------------------------------------------------
// Set default token to load initial data from backend
/*
 async function setDefaultToken(){
    const url = urls();
    const token = userTokenStore();
    console.log("BEFORE login system")
    const headers = { 'Content-Type': 'application/json', 'X-CSRFTOKEN': ""}
    console.log("BEFORE login system")
    const login = await axios.post<{access: string, refresh: string, warning_message: [string] | null}>(
        `${url.baseURL}login/`,system, {headers:headers}).then( r => r.data )
    token.$state.access = login.access;
    token.$state.refresh = login.refresh;
    //TODO: check if warning message is present and in case show it at the login and reject the user login request
    token.$state.isAuth = true;
    console.log("AFTER login system -> SYSTEM-token-created", login);
    return login.warning_message;
}
*/

async function setDefaultToken(){
    const url = urls();
    const token = userTokenStore();
    const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': '', 'X-CSRFTOKEN': ""
    },
    body: JSON.stringify(system)
  };
   document.cookie = '';
   const login = await fetch(`${url.baseURL}login/`, requestOptions)
   const data = await login.json()
   console.log("LOGIN", data)
    token.$state.access = data.access;
    token.$state.refresh = data.refresh;
    token.$state.isAuth = true;
    console.log("AFTER login system -> SYSTEM-token-created", data);
    return data.warning_message;
}


//---------------------------------------------------------
// set language
async function setLanguage(){
    const deviceStatus = useDeviceStatus(pinia);
    const accountData = useAccountDataUser(pinia);

    try {
        const exists = await piniaStorageDB.loadUserDataAccount();
        if (!exists) {
        accountData.$state.language = deviceStatus.language;
        console.log("PINIA-userAccount-notExists > default language:", accountData.$state.language)}
        else { 
            console.log("PINIA-userAccount-exists", accountData.$state.language)
        }  
    } catch {
        accountData.$state.language = deviceStatus.language;
        console.log("PINIA-userAccount--NOT", accountData.$state.language);
    }
}

// -----------------------------------------------

export { systemDefinitions, openCreateLocalDBs, createPiniaStores, setLanguage, setDefaultToken }