import { labelsDataDB } from "@/persistentStorage/appInterface";
import {useFonts, IBFont} from "@/stores/pageLayoutsStore";


interface IFont {
    "font-family": string|undefined,
    "text-transform": string|undefined,
    "font-weight": string|undefined,
    "font-size": string|undefined,
}

async function getFont(fontName:string):Promise<IFont>{
   const fontsStore = useFonts();
   //const fnt = fontsStore.getFont(fontName);
   return fontsStore.getFont(fontName)
}

function generateFont(fontName:string) :IFont{
    let size = 0
    let uppercase = false
    let bold = false
    const font_family = "Roboto, sans-serif"
    
    const sizeString = fontName.slice(4)
    size = Number.parseInt(sizeString)
    uppercase = fontName.slice(0, 2) === 'ca'
    bold = fontName.slice(2,4) === 'bo'

    console.log("FONT-GENERATED")

    return {
        "font-family": font_family,
        "text-transform": uppercase ? 'uppercase' : 'none',
        "font-weight": bold ? 'bold' : 'normal',
        "font-size": `${size}px`,
    }
}


export { IFont, getFont }