<template>
    <ion-page>
        <ion-content>
            <div class="pop-up-message-wrapper">
                <pop-up-mask>

                    <template #icon>
                        <img :src="icon" />
                    </template>

                    <template #title>
                        <h2 id="title"> {{ layout?.labels.title }} </h2>
                    </template>

                    <template #description>
                        <div class="description-wrapper">
                            <span v-for="(line, key) in layout?.labels.lines" :key="key" v-html="line">
                            </span>

                            <div class="star-wrapper">
                                <StarRating
                                    v-model:rating="rating"
                                />
                            </div>

                        </div>
                    </template>

                    <template #button>
                        <div class="wks-open-input-wrapper">
                            <WksOpenInput
                                :media-button="mediaButton"
                                :placeholder="layout?.helps.msg"
                                @set-media="catchFeedback"
                            />
                        </div>
                    </template>
                </pop-up-mask>
            </div>
        </ion-content>
    </ion-page>
</template>


<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted, watch } from 'vue'
import { IonContent, IonPage } from "@ionic/vue";
import PopUpMask from '@/modules/a.worksheets/views/components/feedback/PopUpMask.vue'
import router from '@/router';
import { getComputedPageLayout, getIcon } from '@/helpers/api';
import StarRating from '@/modules/a.worksheets/views/components/StarRating.vue';
import WksOpenInput from '@/modules/a.worksheets/views/components/footer/WksOpenInput.vue';
import { createMemoryHistory } from 'vue-router';
import { SolutionData, useWorksheetHandler } from '@/globals/helpers/worksheetHandler';
import { worksheetService } from '@/modules/a.worksheets/services/worksheet.service';
import { useModalManager, Wks_Modals } from '@/modules/a.worksheets/services/modalManager';

interface _1614A {
    labels: {
        title: string, lines: Array<string>, button: string, next: string,
    },
    helps: {
        msg: string
    }
}





const mediaButton = ref<Array<any>>([])

const icon = ref<string>()
const layout = getComputedPageLayout<_1614A>('1614A')
onBeforeMount(async () => {
    icon.value = await getIcon('1614A', 'icon')

    // const photo = await createBtn('photo', true)
    // if(photo) mediaButton.value.push(photo)
    
    // const audio = await createBtn('audio', true)
    // if(audio) mediaButton.value.push(audio)


})

const rating = ref<number>(0)



async function createBtn(type: 'audio' | 'document' | 'photo' | 'video' | 'expand' | 'pdf', isEnabled: boolean | undefined): Promise<undefined | any> {
  if (isEnabled) {
    const resolvedSrc = await getIcon("1602", type)
    return {
      src: resolvedSrc ?? '',
      route: btnRoutes[type],
      name: type === 'expand' ? 'text' : type
    }
  }
}

const btnRoutes = {
  photo: 'worksheet-take-photos',
  video: 'worksheet-take-videos',
  audio: 'worksheet-take-audios',
  document: 'worksheet-upload-document',
  pdf: 'worksheet-upload-pdf',
  expand: 'text-editor',
}

function close() {
    router.back()
}

const wh = useWorksheetHandler()
async function catchFeedback(data: string | File[], type: SolutionData){
    const response = await sendFeedback(wh.wksAnsUUID.value, data, type)
}

const modalManager = useModalManager()

async function sendFeedback(user_wks_status: string, data: string | File[], type: SolutionData){
    let stringVal = ''
    let fileArr: Array<File> = []

    if(typeof data === 'string'){
        stringVal = data
    }else{
        fileArr = data
    }
    const response = await worksheetService.postFeedback(user_wks_status, rating.value, stringVal, fileArr)

    if(response.status === 200){
        modalManager.open(Wks_Modals.FEEDBACK_SUCCESS)
    }
}

</script>

<style scoped>

.pop-up-message-wrapper{
    width: 100%;
    height: 100%;
}

ion-content::part(background) {
    background-color: transparent;
}

#title{
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    text-transform: var(--title-font-transform);
    font-weight: var(--title-font-weight);
    text-align: center;
}
.description-wrapper{
    font-family: var(--description-font-family);
    font-size: var(--description-font-size);
    text-transform: var(--description-font-transform);
    font-weight: var(--description-font-weight); 
    color: var(--milage-color-default-body);
}
.description-wrapper span{
    display: block;
    text-align: center;
}
img{
    width: 100%;
}
.star-wrapper{
    margin-top: 5%;
    height: 4vh;
}
.wks-open-input-wrapper{
    width: 100%;
}
</style>

<style>
#worksheet-ion-page.ion-page-hidden.ion-page[aria-hidden="true"] {
    display: block;
}
</style>

