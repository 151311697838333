// loadingState.ts
import { computed, ref } from 'vue';

const isLoading = ref(false);

class SpinnerManager {
  pendingTasks = ref(new Set());

  registerTask(identifier?: string) {
    if (!identifier) {
      identifier = Math.random().toString(36)
    }
    if(this.pendingTasks.value.has(identifier)) {
      console.warn(`Task with identifier ${identifier} already exists`)
    }

    console.log(`Registering task with identifier ${identifier}`)
    this.pendingTasks.value.add(identifier);
  }

  unregisterTask(identifier: string) {
    console.log('Unregistering task with identifier', identifier)
    this.pendingTasks.value.delete(identifier);
  }


  readonly isLoading = computed(() => this.pendingTasks.value.size > 0)
}


const setLoading = (value: boolean) => {
  isLoading.value = value;
};


const spinnerManager = new SpinnerManager();

export {setLoading as setLoadingSpinner, isLoading, spinnerManager}