import { instance }  from '@/services/axiosConfig';
import { url } from "@/stores/urls";
import { AxiosResponse } from 'axios';

class RequestsService {
  getWithParams<T>(endpoint:string, params:any) {
    console.log("params", params)
    return instance.get<T>(`${url.baseURL}${endpoint}/`, {params});
  }
  get<T>(endpoint:string) : Promise< AxiosResponse<T> > {
    return instance.get<T>(`${url.baseURL}${endpoint}/`);
  }
  get2<T>(url:string) : Promise< AxiosResponse<T> > {
    return instance.get<T>(`${url}`);
  }
  getDomain<T>(endpoint: string): Promise<AxiosResponse<T>> {
    return instance.get<T>(`${url.domainURL}${endpoint}/`) 
  }
  post<T>(endpoint:string, data:any): Promise< AxiosResponse<T> > {
    return instance.post<T>(`${url.baseURL}${endpoint}/`, data);
  }
  put<T>(endpoint:string, data:any): Promise< AxiosResponse<T> > {
    return instance.put<T>(`${url.baseURL}${endpoint}/`, data);
  }
  getFile(filepath:string, filename:string){
    return instance.get(`${url.mediaURL}${filepath}/${filename}`);
  }
  getServerMediaFile<T>(filepath:string, filename:string, filetype:string){
    const params = {filepath:filepath,filename:filename, filetype:filetype};
    return instance.get<T>(`${url.baseURL}get-media-file/`, {params, responseType: 'arraybuffer'});
  }
  delete<T>(endpoint: string, uuid:string): Promise<AxiosResponse<T>> {
    return instance.delete<T>(`${url.baseURL}${endpoint}/?uuid=${uuid}`);
  }
}
const requestService = new RequestsService()
export default requestService;