import { computed, ref } from "vue";
import { PDFDocumentProxy } from "pdfjs-dist";

export const useControls = () => {

  const pdfPages = ref<number>();
  const pageNr = ref(1);
  const setPageNr = (n: number) => (pageNr.value = n);

  const events = {
    loadedPdf: (pdfProxy: PDFDocumentProxy) => pdfPages.value = pdfProxy.numPages,
  }

  const isMove = computed(() => (pdfPages.value ?? 0) > 1);
  const isLeftArrowGray = computed(() => pageNr.value === 1);
  const isRightArrowGray = computed(() => pageNr.value === pdfPages.value);

  return {
    pageNr,
    setPageNr,
    pdfPages,
    events,
    isMove,
    isLeftArrowGray,
    isRightArrowGray,
  }
};
