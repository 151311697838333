import { Stringified, ionicStorageClass } from "@/persistentStorage/ionicStorageClass";


class IconsStorageFiles extends ionicStorageClass{
    constructor(name:string, storeName:string){
        super (name, storeName)
    }

    //------Token------------------------------
    async saveFile(key:string, icon:string) {
        await this.setDatabaseItem(key, icon as Stringified<string>);
    }
    async loadFile(key:string) {
        const icon = await this.getDatabaseItem(key);
        return icon
    }

}

const iconsFilesDB = new IconsStorageFiles("icons-storage-files","icons-storage-files-db");
export { iconsFilesDB }