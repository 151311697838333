import {
  onlineDataFetcher,
  offlineDataFetcher,
} from "@/modules/0.startApp/helpers/loadDefaultData";
import { useFonts, useIcons, usePageLayouts } from "@/stores/pageLayoutsStore";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { setDefaultToken } from "@/modules/0.startApp/helpers/initialConfig";
import NetworkError from "@/globals/pages/NetworkError.vue";
import { modalController } from "@ionic/vue";

async function loadOffline(
  fontsStore: ReturnType<typeof useFonts>,
  pageLayoutsStore: ReturnType<typeof usePageLayouts>,
  accountData: ReturnType<typeof useAccountDataUser>,
  deviceStatus: ReturnType<typeof useDeviceStatus>,
  iconStore: ReturnType<typeof useIcons>
) {
  await offlineDataFetcher.loadFonts(fontsStore);
  await offlineDataFetcher.loadPageLayoutsDefaults(
    pageLayoutsStore,
    accountData.language,
    deviceStatus.isDarkMode
  );
  await offlineDataFetcher.loadIconsJSON(iconStore);
  await offlineDataFetcher.loadIconsStart();
  await offlineDataFetcher.loadIconsAll(iconStore);
}

async function loadOnline(
  fontsStore: ReturnType<typeof useFonts>,
  pageLayoutsStore: ReturnType<typeof usePageLayouts>,
  accountData: ReturnType<typeof useAccountDataUser>,
  deviceStatus: ReturnType<typeof useDeviceStatus>,
  iconStore: ReturnType<typeof useIcons>
) {
  const error: null | string[] = await setDefaultToken();
  if (error){
    deviceStatus.invalidVersion = {message: error};
  }

  await onlineDataFetcher.loadFonts(fontsStore);
  await onlineDataFetcher.loadPageLayoutsDefaults(
    pageLayoutsStore,
    accountData.language,
    deviceStatus.isDarkMode
  );

  await onlineDataFetcher.loadIconsJSON(iconStore);
  await onlineDataFetcher.loadIconsStart();
  await onlineDataFetcher.loadIconsAll(iconStore);
}


async function presentAlert() {
    const modal = await modalController.create({
      component: NetworkError,
    });
    modal.present();
  }

export { loadOffline, loadOnline, presentAlert };
