<template>
  <div v-if="getShowSpinner" class="spinner-overlay">
    <ion-spinner
      name="lines"
      :duration="spinnerDuration"
      :paused="spinnerPaused"
      :enabled="spinnerEnabled"
    ></ion-spinner>
  </div>
</template>

<script lang="ts">
import { IonSpinner } from "@ionic/vue";
import { computed, defineComponent, provide } from "vue";
import { isLoading, spinnerManager } from '@/globals/pages/spinnerControl'

export default defineComponent({
  name: "SpinnerOverlay",
  components: { IonSpinner },
  props: {
    showSpinner: {
      type: Boolean,
      default: false, // Change this value to control when to show/hide the spinner
    },
    spinnerName: {
      type: String,
      default: "lines", // Change this value if you want a different spinner style
    },
    spinnerDuration: {
      type: Number,
      default: 1000, // Change this value to specify a different duration in milliseconds
    },
    spinnerPaused: {
      type: Boolean,
      default: false, // Change this value to pause or resume the spinner animation
    },
    spinnerEnabled: {
      type: Boolean,
      default: true, // Change this value to enable or disable the spinner
    },
  },
  computed:{
    getShowSpinner(){
      return this.showSpinner || spinnerManager.isLoading.value
    }
  }
});



</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Change the background color and opacity as needed */
  z-index: 9999;

  --color: var(--milage-color-primary-shade);
}
ion-spinner {
  width: 5rem;
  height: 5rem;
}
</style>
@/globals/pages/spinnerControl