
import pinia from "@/plugins/piniaStore";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { labelsDataDB } from "@/persistentStorage/appInterface";
import { useAccountDataUser, IUserData } from "@/modules/5.account/stores/accountDataStore"; 
import {useFonts, usePageLayouts, useIcons} from "@/stores/pageLayoutsStore";
import { iconsLocalFiles } from "@/persistentStorage/iconsLocalFiles";

 

interface IDataFetcher {
    loadFonts:(fontStore: ReturnType<typeof useFonts>)=>void,
    loadIconsJSON:(iconStore: ReturnType<typeof useIcons>)=>void,
    loadPageLayoutsDefaults:(pageLayoutStore: ReturnType<typeof usePageLayouts>, language: string, isDarkMode: boolean)=>void,
    loadIconsStart:()=>void,
    loadIconsAll:(iconStore: ReturnType<typeof useIcons>)=>void
}

class OnlineDataFetcher implements IDataFetcher {
    async loadFonts(fontStore: ReturnType<typeof useFonts>){
        const fonts = await labelsDataDB.downloadAllFonts();
        fontStore.loadAllFonts(fonts);  
    }
    async loadIconsJSON(iconStore: ReturnType<typeof useIcons>){
        const icons = await labelsDataDB.downloadIconsJSON();
        const createOrUpdate = icons['createOrUpdate'] as boolean
        const iconsPages = icons['data']
        await iconStore.loadAllIcons(createOrUpdate, iconsPages['last_update'], iconsPages['data']); 
    }
    async loadPageLayoutsDefaults(pageLayoutStore: ReturnType<typeof usePageLayouts>, language: string, isDarkMode: boolean){
        const pages = await labelsDataDB.downloadPageLayoutsJSON2DB(language);
        const createOrUpdate = pages['createOrUpdate'] as boolean;
        const pageLayout = pages['data']
        await pageLayoutStore.loadScreens(createOrUpdate, isDarkMode, language, pageLayout['last_update'], pageLayout["data"]); 
    }
    async loadIconsStart(){
        const lst1 = ['1010', '1020']
        await iconsLocalFiles.readWriteIconsFromPageList(lst1);
        const lst2 = ['1101', '1110', '1110A', '1110B', '1110C', '1110D']
        await iconsLocalFiles.readWriteIconsFromPageList(lst2);
        const lst3 = ['1120']//, '1121', '1122', '1123', '1124', '1125']
        //'1126', '1127', '1128', '1129', '1130']
        await iconsLocalFiles.readWriteIconsFromPageList(lst3);
        iconsLocalFiles.readWriteIconsFromPageList(lst3);
    }
    async loadIconsAll(iconStore: ReturnType<typeof useIcons>){
        if ((iconStore.isToCreateOrUpdate ) || (iconStore.isToCreateOrUpdate == false )) 
        {
            iconStore.getAllPages.forEach((page) =>{
                //console.log("ICONS-PAGE", page); 
                if (page.icons)  
                  {iconsLocalFiles.readWriteIconsFromPageList([page.screen_id]);} 
            })
            console.log("ICONS-PAGES loaded!")
        }
    }
}

class OfflineDataFetcher implements IDataFetcher {
    async loadFonts(fontStore: ReturnType<typeof useFonts>) {
        const fonts = await labelsDataDB.getLocalFonts();
        fontStore.loadAllFonts(fonts);
    }
    async loadIconsJSON(iconStore: ReturnType<typeof useIcons>){
        const icons = await labelsDataDB.getLocalIconsJSON();
        if(icons){
            const createOrUpdate = icons['createOrUpdate'] as boolean
            const iconsPages = icons['data']
            await iconStore.loadAllIcons(createOrUpdate, iconsPages['last_update'], iconsPages['data']); 
        } 
    }
    async loadPageLayoutsDefaults(pageLayoutStore: ReturnType<typeof usePageLayouts>, language: string, isDarkMode: boolean){
        const pages = await labelsDataDB.getPageLayoutsJSON2DB(language);
        //console.warn("pages: ", pages);
        //const createOrUpdate = pages['createOrUpdate'] as boolean;
        await pageLayoutStore.loadScreens(false, isDarkMode, language, pages.last_update, pages.data);  
    }
    async loadIconsStart(){
        const lst1 = ['1010', '1020']
        await iconsLocalFiles.readWriteIconsFromPageList(lst1);
        const lst2 = ['1101', '1110', '1110A', '1110B', '1110C', '1110D']
        await iconsLocalFiles.readWriteIconsFromPageList(lst2);
        const lst3 = ['1120']//, '1121', '1122', '1123', '1124', '1125']
        //'1126', '1127', '1128', '1129', '1130']
        await iconsLocalFiles.readWriteIconsFromPageList(lst3);
        iconsLocalFiles.readWriteIconsFromPageList(lst3);
    }
    async loadIconsAll(iconStore: ReturnType<typeof useIcons>){
        if ((iconStore.isToCreateOrUpdate ) || (iconStore.isToCreateOrUpdate == false )) 
        {
            iconStore.getAllPages.forEach((page) =>{
                //console.log("ICONS-PAGE", page); 
                if (page.icons)  
                  {iconsLocalFiles.readWriteIconsFromPageList([page.screen_id]);} 
            })
            console.log("ICONS-PAGES loaded!")
        } 
    }
}




const onlineDataFetcher = new OnlineDataFetcher();
const offlineDataFetcher = new OfflineDataFetcher();
export { onlineDataFetcher, offlineDataFetcher };