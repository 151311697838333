import { ionicStorageClass } from "@/persistentStorage/ionicStorageClass";
import { getDir, createFolder } from "@/helpers/imagesReadWrite";

/**
 * Class WksStorageService extends the functionality of ionicStorageClass.
 * It provides specialized methods for storing and retrieving worksheet data in a specific storage structure.
 */
class WksStorageService extends ionicStorageClass {
  folderName: string;     // The name of the folder where data will be physically stored.

  private isInitialized: boolean = false; // Flag to check if the storage has been initialized.

  /**
   * Constructs a new WksStorageService instance.
   * 
   * @param {string} name - The name of the storage service.
   * @param {string} storeName - The name of the store within the storage service.
   * @param {string} folderName - The name of the folder where data will be stored. Defaults to 'wks_db_folder'.
   */
  constructor(
    name: string,
    storeName: string,
    folderName: string = "wks_db_folder"
  ) {
    super(name, storeName);
    this.folderName = folderName;
  }

  /**
   * Retrieves the data associated with the mountKey from storage.
   * Ensures that the storage directory is created before accessing the data.
   * 
   * @param {string} mountKey - The name of the folder where data will be stored. We use User UUIDs as mountKeys.
   * @returns {Promise<string | null>} A promise that resolves to the data, or null if not found.
   */
  async get(mountKey: string): Promise<string | null> {
    if (!this.isInitialized) {
      try {
        await this.createWksDIR()
      }catch (e) {
        console.warn(e);
        this.isInitialized = true;
      }
    }
    return await super.getDatabaseItem(mountKey);
  }

  /**
   * Stores the provided value under the mountKey in storage.
   * Ensures that the storage directory is created before storing the data.
   * 
   * @param {any} value - The data to store in the storage.
   */
  async set(mountKey: string, value: any): Promise<void> {
    if (!this.isInitialized) await this.createWksDIR();
    await super.setDatabaseItem(mountKey, value);
  }

  /**
   * Asynchronously creates a directory for storing worksheet data.
   * It checks if the directory under the variable named `folderName` exists,
   * and creates it if it doesn't.
   */
  private async createWksDIR() {
    const dir = await getDir("");
    if (!dir.find((x) => x.name === this.folderName)) {
      await createFolder(this.folderName);
    }
    this.isInitialized = true;
  }
}

export default WksStorageService;