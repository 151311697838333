import { Stringified, ionicStorageClass } from "@/persistentStorage/ionicStorageClass";

import { userTokenStore, IToken } from "@/stores/tokenStore";

import { useHeaderData, IHeaderData } from "@/globals/stores/storeHeaderData";
import { useFooterData, IFooterData } from "@/globals/stores/storeFooterData";
import { useDropdownUser, IDropdownItem } from "@/globals/stores/storeDropdownData";




import { useAccountDataUser, IUserData } from "@/modules/5.account/stores/accountDataStore";

class PiniaStorageDB extends ionicStorageClass{
    constructor(name:string, storeName:string){
        super (name, storeName)
    }

    //------Token------------------------------
    async saveToken() {
        const token = userTokenStore()
        const st = JSON.stringify(token.$state) as Stringified<IToken>;
        await this.setDatabaseItem("token", st);
    }
    async loadToken() {
        const token = userTokenStore()
        const tk = await this.getDatabaseItem("token") as any;
        const obj = JSON.parse(tk) as IToken
        token.$patch(obj);
    }

    //----Header-Footer-Data----------------------
    async saveHeaderData(){
        const headData = useHeaderData()
        const str = JSON.stringify(headData.$state) as Stringified<any>;
        await this.setDatabaseItem("header-data", str);
    }
    async saveFooterData(){
        const footData = useFooterData()
        const str = JSON.stringify(footData.$state) as Stringified<any>;
        await this.setDatabaseItem("footer-data", str);
    }

    async saveDropdownData(){
        const dropData = useDropdownUser()
        const str = JSON.stringify(dropData.$state) as Stringified<any>;
        await this.setDatabaseItem("dropdown-data", str);
    }


    async loadHeaderData(){
        const headData = useHeaderData()
        const hf = await this.getDatabaseItem("header-data") as any;
        const obj = JSON.parse(hf) as IHeaderData;
        headData.$state = obj
    }
    async loadFooterData(){
        const footData = useFooterData()
        const hf = await this.getDatabaseItem("footer-data") as any;
        const obj = JSON.parse(hf) as IFooterData;
        footData.$state = obj
    }
    async loadDropdownData(){
        const dropData = useDropdownUser()
        const hf = await this.getDatabaseItem("footer-data") as any;
        const obj = JSON.parse(hf);
        dropData.$state = obj        
    }    
    
    //----User-Data-Account (profile)---------------
    async saveUserDataAccount(){
        const userData = useAccountDataUser();
        const json = JSON.stringify(userData.$state) as Stringified<IUserData>;
        const dt = await this.setDatabaseItem("user-data-profile", json); 
        console.log("SET-DB-", dt);
    }
    async loadUserDataAccount(){
        const userData = useAccountDataUser();
        const usrData = await this.getDatabaseItem("user-data-profile"); 
        if (usrData){
            const obj = JSON.parse(usrData) as IUserData;
            userData.$patch(obj);
            console.log("loadUserDataAccount",obj)
            return true
        } else {console.log("UserDataAccountDB do not exists"); return false}
    }
    //----User-Preferences-(to personalize navigation)------------

}

const piniaStorageDB = new PiniaStorageDB("pinia-storage-data","pinia-storage-db");
export { piniaStorageDB }