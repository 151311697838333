import { defineStore } from 'pinia';
import { IMedia} from "@/modules/a.worksheets/helpers/mediaHandler";
import { SolutionData } from '@/globals/helpers/worksheetHandler';
// TODO: CROSS PAGE COMMUNICATION (Media Page -> WksOpenInput)

interface IOpenInputMediaStore {
    openInput: Map<string, {
        data: string | boolean | File[],
        type: SolutionData
    }>
}

const openInputMediaData = {
    openInput: new Map<string, { data: string | File[], type: SolutionData }>()
}

const useOpenInputMediaStore = defineStore({
    id: 'WksOpenInputMediaModule',
    state: (): IOpenInputMediaStore => openInputMediaData,
    getters: {
    },
    actions: {
        setMediaData(inputId: string, data: string | File[], type: SolutionData) {
            this.openInput.set(inputId, { data: data, type: type })
        },
        getMediaData(inputId: string) {
            return this.openInput.get(inputId)
        },
        clearMediaData(inputId: string) {
            this.openInput.delete(inputId)
        }
    }
})


export { useOpenInputMediaStore };
