class CssUtils {
    private svg: string;

    constructor(svg: string) {
        this.svg = svg;
    }

    setSvgFill(color: string): CssUtils {
        this.svg = this.svg.replaceAll(/fill=".*?"/g, `fill="${color}"`);
        return this;
    }

    addSvgAttribute(attr: string, value: string): CssUtils {
        this.svg = this.svg.replaceAll(/<svg /g, `<svg ${attr}="${value}" `);
        return this;
    }
    removeSvgAttribute(attribute: string): CssUtils {
        this.svg = this.svg.replaceAll(new RegExp(`${attribute}=".*?"`, 'g'), '');
        return this;
    }
    addStyle(style: string): CssUtils {
        this.svg = this.svg.replaceAll(/<svg /g, `<svg style="${style}" `);
        return this;
    }

    build(): string {
        return this.svg;
    }
}



export default CssUtils;