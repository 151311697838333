
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';
import { Motion } from '@capacitor/motion';

import pinia from "@/plugins/piniaStore";
import { useDeviceStatus } from "@/stores/deviceStatus";

const deviceStatus =  useDeviceStatus(pinia);

async function netListener() { 
  const status = await Network.getStatus()  
  deviceStatus.isNetConnected = status.connected;
  await Network.addListener('networkStatusChange', status => {
    console.warn("Net status changed: ", status.connected);
    deviceStatus.isNetConnected = status.connected;
  })
}

async function orientationListener() {
    await Motion.addListener("orientation", event => {
        console.log('Device motion event:', event);
    }) 
}

function appStateChange(){
App.addListener('appStateChange', ({ isActive }) => {
    console.log('App state changed. Is active?', isActive);
  });
}

function backButtonListener() {
    App.addListener('backButton', status => {
        console.log("BackButonListener", status);
    } )
}

export {backButtonListener, appStateChange, netListener, orientationListener }