import { defineStore } from "pinia";
import api from "@/services/requests";
import { url } from "@/stores/urls";
import { useDropdownUser } from "@/globals/stores/storeDropdownData";

interface IData {
  avatar: string;
}

interface IUserData {
  titlePage: string;
  uuid: string;
  name: string;
  nickname: string | null;
  country: string;
  language: string;
  birthdate: string | null;
  auth_account: string;
  is_register_completed: boolean;
  is_teacher: boolean;
  student_code: string;
  had_activated: boolean;
  allow_push_notifications: boolean;
  allow_email_notifications: boolean;
  permission_level: number;

  email: string;
  avatar: string;
  resetCode: string;

  shortname: string;
  initials: string;

  //not to store in the user profile
  password: string;
  rememberMe: boolean;
}

const useAccountDataUser = defineStore({
  id: "AccountUserDataModule",
  state: (): IUserData => ({
    titlePage: "",
    uuid: "",
    name: "",
    shortname: "",
    nickname: "",
    birthdate: "",
    email: "",
    student_code: "",
    avatar: "",
    resetCode: "",

    country: "pt",
    language: "PT",
    initials: "",
    is_register_completed: true,
    auth_account: "milage",
    is_teacher: false,
    had_activated: true,
    allow_push_notifications: false,
    allow_email_notifications: true,
    permission_level: 0,

    password: "",
    rememberMe: false,
  }),
  actions: {
    async updateAccountData(
      name: string,
      nickname: string,
      birthdate: string,
      email: string,
      password: string
    ) {
      const response = await api.put<{ password?: string, email?: string }>("update-account", {
        name: name,
        nickname: nickname,
        birthdate: birthdate,
        email: email.toLowerCase(),
        password: password,
      });

      if (response.status == 200) {
        this.name = name;
        this.nickname = nickname;
        this.birthdate = birthdate;
        this.email = email;
      }
      return response;
    },
    async updateAvatar(avatarFile: FormData) {
      const response = await api.put("user-avatar", avatarFile);
      if (response.status >= 200 && response.status < 300) {
        const data = response.data as IData;
        if (data?.avatar) {
          this.avatar = data?.avatar;
          const dropdownData = useDropdownUser();
          dropdownData.getItem.avatar = data.avatar;
          console.log("successfully updated avatar", response);
        }
      }
      return response;
    },
  },
  getters: {
    profile: (state) => state,
    getLanguage: (state) => state.language,
    getStudentCode: (state) => state.student_code,
    getAvatar: (state) => {
      // update to show the currently selected user avatar
      
      const dropdownData = useDropdownUser();
      //return url.base + state.avatar;
      return dropdownData.getAvatar;
    },
  },
});

export { useAccountDataUser, IUserData };
