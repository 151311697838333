<template>
  <div @click="emit('click', source)" class="maxSize">
    <LazyVideo :videoSrc="source" :thumbnail-src="thumbnail" @loaded="loaded" />
  </div>
  <!-- <div v-show="!isLoaded" class="loading-box">...</div> -->
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import { getVideoCover } from "@rajesh896/video-thumbnails-generator";
import LazyVideo from "@/globals/components/LazyVideo.vue";

const props = defineProps({
  source: { type: String, required: true },
  controls: { type: Boolean, default: () => false },
});

const emit = defineEmits<{
  (e: "click", source: string): void;
  (e: "loaded"): void;
}>();

const thumbnail = ref<string>();
const src = ref<string>("");
const isLoaded = ref(false);
onBeforeMount(async () => {
  thumbnail.value = await generateThumbnail(src.value);
});

async function generateThumbnail(blobUrl: string): Promise<undefined | string> {
  console.log("generate thumbnail!");
  let thumbnail = undefined;
  try {
    console.log("getVideoCover", blobUrl);
    thumbnail = await getVideoCover(blobUrl);
  } catch (e) {
    console.log("error: ", e);
  }
  return thumbnail;
}

watch(
  computed(() => props.source),
  async (newVal, oldVal) => {
    isLoaded.value = false;
    src.value = props.source;
    thumbnail.value = await generateThumbnail(src.value);
  },
);

function loaded() {
  console.log("loaded from video viewer");
  //spinnerManager.unregisterTask("loading media");
  isLoaded.value = true;
  emit("loaded");
}
</script>

<style scoped>
video,
div {
  width: 100%;
  border-radius: 1rem;
}
div {
  position: relative;
}

.maxSize {
  width: 100%;
  height: 100%;
}
.loading-box {
  width: 100%;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
