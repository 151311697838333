import axios, { AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import pinia from "@/plugins/piniaStore";
import { urls } from "@/stores/urls";
import { userTokenStore } from "@/stores/tokenStore";

const baseUrl = urls(pinia);
const token = userTokenStore(pinia);

export const instance = axios.create({
    baseURL: baseUrl.baseURL,
});

//-------------------

instance.interceptors.request.use(successfulRequest,failedRequest);
instance.interceptors.response.use(successfulResponse,failedResponse);

function successfulRequest<T>(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig<T> {
  if (token.getIsAuth) {
      config.headers["Authorization"] = `Bearer ${token.access}`;
  }
  //console.log("REQUEST", config);
  return config; 
}

function failedRequest(error: AxiosError): Promise<never> {
  console.log("BACKEND ERROR", error);
  return Promise.reject(error);
}

function successfulResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function failedResponse(error: AxiosError): AxiosResponse | undefined { //TODO: throw error instead of return error.response
  console.log("NETWORK ERROR - Interceptor-failedResponse", error.code);
  return error.response;
}

//-------------------

export default instance;