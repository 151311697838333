import { IPageLayout } from "@/helpers/api";
import { defineStore } from "pinia";

interface IPageLayoutObj {
  screen_id: string;
  status: boolean;
  language: string;
  labels: object;
  helps: object;
  errors: object;
  commands: object;
  last_update: string;
}

interface IScreen {
  isDarkmode: boolean;
  language: string;
  last_update: string;
  createOrUpdate: boolean;
  screens: Map<string, IPageLayoutObj>;
}

const usePageLayouts = defineStore({
  id: "PageLayouts",
  state: (): IScreen => ({
    isDarkmode: false,
    language: "EN",
    last_update: "",
    createOrUpdate: true,
    screens: new Map(),
  }),
  getters: {
    getPageLayout:
      (state) =>
      (key: string): IPageLayoutObj | undefined => {
        const layout = state.screens.get(key);
        if (layout)
          return {
            labels: layout.labels,
            helps: layout.helps,
            errors: layout.errors,
            commands: layout.commands,
          } as IPageLayoutObj;
        else {
          return undefined;
        }
      },
    isToCreateOrUpdate: (state) => state.createOrUpdate,
  },
  actions: {
    async loadScreens(
      createOrUpdate: boolean,
      isDarkmode: boolean,
      language: string,
      last_update: string,
      pagLayouts: IPageLayoutObj[]
    ) {
      this.isDarkmode = isDarkmode;
      this.language = language;
      this.last_update = last_update;
      this.screens = new Map(pagLayouts.map(layout => [layout.screen_id, layout]));

      this.createOrUpdate = createOrUpdate;
    },
  },
});

//-----------------------------------------------------
interface IFont {
  "font-family": string | undefined;
  "text-transform": string | undefined;
  "font-weight": string | undefined;
  "font-size": string | undefined;
}

interface IBFont {
  //Fonts from the backend
  key: string;
  font: string;
  transform: string;
  weight: string;
  size: string;
}
interface IFonts {
  fonts: Array<IBFont>;
}

const useFonts = defineStore({
  id: "Fonts",
  state: (): IFonts => ({
    fonts: [],
  }),
  getters: {
    getFont: (state) => (key: string) => {
      const selected = state.fonts.find(
        (element) => element.key == key
      ) as IBFont;
      const fnt = {} as IFont;
      try {
        fnt["font-family"] = selected.font;
        fnt["text-transform"] = selected.transform;
        fnt["font-weight"] = selected.weight;
        fnt["font-size"] = selected.size;
        return fnt;
      } catch {
        return fnt;
      }
    },
  },
  actions: {
    async loadAllFonts(data: Array<IBFont>) {
      this.$state.fonts = [];
      data.forEach((element) => {
        this.$state.fonts.push(element);
      });
    },
  },
});

//------------------------------------------
interface IIcons {
  screen_id: string;
  icons: object;
  iconsdark: object;
  last_update: string;
}

interface IRec {
  last_update: string;
  createOrUpdate: boolean;
  icons: Array<IIcons>;
}

const useIcons = defineStore({
  id: "Icons",
  state: (): IRec => ({
    last_update: "",
    createOrUpdate: true,
    icons: [],
  }),
  getters: {
    getPage: (state) => (screen: string) => {
      const selected = state.icons.find(
        (element: IIcons) => element.screen_id == screen
      ) as IIcons;
      //console.log("PINIA-getIconsPage", screen, selected, state.icons)
      if (selected)
        return {
          icons: selected.icons,
          iconsdark: selected.iconsdark,
        };
      else {
        return { icons: "", iconsdark: "" };
      }
    },
    isToCreateOrUpdate: (state) => state.createOrUpdate,
    getAllPages: (state) => state.icons,
  },
  actions: {
    async loadAllIcons(createOrUpdate: boolean, last_update: string, data: []) {
      (this.$state.last_update = last_update),
        (this.$state.createOrUpdate = createOrUpdate);
      this.$state.icons = data;
    },
  },
});

//------------------------------------------

export { usePageLayouts, IPageLayoutObj, useFonts, IBFont, useIcons, IIcons };
