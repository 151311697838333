

import { defineStore } from 'pinia';
import pinia from "@/plugins/piniaStore";
import { useDropdownUser } from './storeDropdownData';
import { IPageLayout } from '@/helpers/api';
import { getIcon } from '@/helpers/api';
const dropdownUser = useDropdownUser(pinia);

//---------------------------
interface IHeaderIcon {
    key: string,
    route?: string,
    src?: string, //icon
    amountOfNotifications?: number | 0
}

interface IHeaderData{
    title:string,

    userAvatarRoute:string,
    classAvatarRoute:string,

    userLeftIcons:string[],
    userLeftRoutes:string[],
    userLeftShow:number[],
    userLeftNotificationAmount: number[],

    userRightIcons:string[],
    userRightRoutes:string[],
    userRightShow:number[],
    userRightNotificationAmount: number[],

    classLeftIcons:string[],
    classLeftRoutes:string[],
    classLeftShow:number[],
    classLeftNotificationAmount: number[],

    classRightIcons:string[],
    classRightRoutes:string[],
    classRightShow:number[],
    classRightNotificationAmount: number[],

    infoUser:string[],
    infoUserRoutes:string[],
    infoClass:string[],
    infoClassRoutes:string[],
    //modalBox data
    modalBoxTitle:string,
    applyBtnText:string,
    buttonsText:string[],
    buttonRouters:string[],
}

export interface IHeaderLayout extends IPageLayout {
    labels: {
      title: string;
      info_user: Array<string>;
      info_class: Array<string>;
      modalBoxTitle: string;
      buttonsText: Array<string>;
      applyBtnText: string;
    };
    icons: {
      webmenu:string,  
      chat: string;
      msg: string;
      ranks: string;
      statistics: string;
      search: string;
    };
    commands: {
        infoUserRoutes: Array<string>;
        infoClassRoutes: Array<string>;

        buttonRoutes: Array<string>;

        userAvatarRoute: string;
        classAvatarRoute: string;

        userLeftIcons: Array<string>;
        userLeftRoutes: Array<string>;

        userRightIcons: Array<string>;
        userRightRoutes: Array<string>;

       classLeftIcons: Array<string>;
       classLeftRoutes: Array<string>;

       classRightIcons: Array<string>;
       classRightRoutes: Array<string>;

       userLeftShow: Array<number>;
       userRightShow: Array<number>;
       classLeftShow: Array<number>;
       classRightShow: Array<number>;
    };
}

const useHeaderData = defineStore("HeaderData",{
    state: ():IHeaderData => 
    ({
            title:"Homepage",
            userAvatarRoute:"user-account",
            classAvatarRoute:"info", //
            // Left icons toolbar
            userLeftIcons:["statistics", "search"],
            userLeftRoutes:["grades", "search"],
            userLeftShow:[0,1],
            userLeftNotificationAmount: [0,0],
        
            classLeftIcons: [],
            classLeftRoutes:[],
            classLeftShow: [],
            classLeftNotificationAmount: [],
        
            // Right icons toolbar
            userRightIcons:["msg", "chat", "ranks"],
            userRightRoutes:["notifications", "chat", "rankings"],
            userRightShow:[0,1,2],
            userRightNotificationAmount: [1,12,0],

            classRightIcons:["msg", "chat", "search"],
            classRightRoutes:["notifications", "chat", "rankings"],
            classRightShow:[0,1,2],
            classRightNotificationAmount: [0,0,0],
            //  
            infoUser:["Classes","Courses","Points","Badges"],
            infoUserRoutes:['','','','', ''],
            infoClass:[ "Students","Worksheets"],
            infoClassRoutes:['',''],
            //modalBox data
            modalBoxTitle:"Select view",
            applyBtnText:"Apply",
            buttonsText:["Add mentee", "Add class"],
            buttonRouters:["", ""],

    }) as IHeaderData,
    actions: {
        createIHeaderIcons(icons: string[], routes: string[], show: number[]){
            const headerIcons: Array<IHeaderIcon> = [];
            show.forEach((i) => {
                const headerIcon: IHeaderIcon = {
                    key: (20 + i).toString(),
                    src: icons[i],
                    route: routes[i]
                }
                if(headerIcon.src){
                    headerIcons.push(headerIcon)
                }
            })
            return headerIcons;
        },
        getRightUserIconData(show: number[]){
            return this.createIHeaderIcons(this.userRightIcons, this.userRightRoutes, show);
        },
        getLeftUserIconData(show: number[]){
            return this.createIHeaderIcons(this.userLeftIcons, this.userLeftRoutes, show);
        },
        getRightClassIconData(show: number[]){
            return this.createIHeaderIcons(this.classRightIcons, this.classRightRoutes, show);
        },
        getLeftClassIconData(show: number[]){
            return this.createIHeaderIcons(this.classLeftIcons, this.classLeftRoutes, show);
        },
        setHeaderData(layout: IHeaderLayout){
            console.log("setting header Layout", layout)
            this.$state.title = layout.labels.title;
            this.$state.modalBoxTitle = layout.labels.modalBoxTitle;
            this.$state.applyBtnText = layout.labels.applyBtnText;
            this.$state.buttonsText = layout.labels.buttonsText; 
            this.$state.infoUser = layout.labels.info_user; 
            this.$state.infoClass = layout.labels.info_class; 
          
    
            this.$state.userLeftIcons=[];
            layout.commands.userLeftIcons.forEach(async (icn) => {
                 const logo = await getIcon("1100H", icn) as string;
                 this.$state.userLeftIcons.push(logo);
            })
    
    
            this.userLeftRoutes = layout.commands.userLeftRoutes;
            this.userLeftShow = layout.commands.userLeftShow;
    
        
            this.$state.userRightIcons=[];
            layout.commands.userRightIcons.forEach(async (icn) => {
                 const logo = await getIcon("1100H", icn) as string;
                 this.$state.userRightIcons.push(logo);
            })
    
    
            this.userRightRoutes = layout.commands.userRightRoutes
            this.userRightShow = layout.commands.userRightShow
        
            this.$state.classLeftIcons=[];
            layout.commands.classLeftIcons.forEach(async (icn) => {
                 const logo = await getIcon("1100H", icn) as string;
                 this.$state.classLeftIcons.push(logo);
            })
    
    
            this.classLeftRoutes = layout.commands.classLeftRoutes
            this.classLeftShow = layout.commands.classLeftShow
        
            this.classRightIcons = []
            layout.commands.classRightIcons.forEach(async (icn) => {
                const logo = await getIcon("1100H", icn) as string;
                this.$state.classRightIcons.push(logo);
           })
    
    
            this.classRightRoutes = layout.commands.classRightRoutes
            this.classRightShow = layout.commands.classRightShow
    
            this.infoUserRoutes = layout.commands.infoUserRoutes
            this.infoClassRoutes = layout.commands.infoClassRoutes
    
            this.userRightNotificationAmount = [0,0,0]
            this.userLeftNotificationAmount = [0,0,0]
            this.classLeftNotificationAmount = [0,0,0]
            this.classRightNotificationAmount = [0,0,0]
        }
    },
    getters:{
        getAvatarRoute: (state) => (dropdownUser.getKind === "classroom" ? state.classAvatarRoute : state.userAvatarRoute),
//        getAvatar: (state) => dropdownUser.getAvatar,
//        getStats: (state) => dropdownUser.$state.dropdownList[dropdownUser.$state.itemSelected].stats,
        getTitle: (state) => state.title,
//        getIsUser: (state) => !(dropdownUser.$state.dropdownList[dropdownUser.$state.itemSelected].kind==='classroom'),
//        getNameItemSelected: (state) => dropdownUser.$state.dropdownList[dropdownUser.$state.itemSelected]['name'],
//        getDropdownList: (state) => dropdownUser.$state.dropdownList, 
        getRightIcons: (state) => {
            const headerIcons : Array<IHeaderIcon> = []
            if(dropdownUser.getKind==="classroom"){
                state.classRightShow.forEach((i) => {
                    const hI = {} as IHeaderIcon; 
                    hI.key = (10+i).toString();
                    hI.src = state.classRightIcons[i];
                    hI.route = state.classRightRoutes[i];
                    headerIcons.push(hI);
                })
            } else {
                state.userRightShow.forEach((i) => {
                    const hI = {} as IHeaderIcon; 
                    hI.key = (20+i).toString();
                    hI.src = state.userRightIcons[i];
                    hI.route = state.userRightRoutes[i];
                    headerIcons.push(hI);
                })
            }
            return headerIcons
        },
        getLeftIcons: (state) => {
            const headerIcons : Array<IHeaderIcon> = []
            if(dropdownUser.getKind==="classroom"){
                state.classLeftShow.forEach((i) => {
                    const hI = {} as IHeaderIcon; 
                    hI.key = (30+i).toString();
                    hI.src = state.classLeftIcons[i];
                    hI.route = state.classLeftRoutes[i];
                    headerIcons.push(hI);
                })
            } else {
                state.userLeftShow.forEach((i) => {
                    const hI = {} as IHeaderIcon; 
                    hI.key = (40+i).toString();
                    hI.src = state.userLeftIcons[i];
                    hI.route = state.userLeftRoutes[i];
                    headerIcons.push(hI);
                })
            }
            return headerIcons
        },

    }
})
//******************************************************************


export {useHeaderData, IHeaderData, IHeaderIcon } 