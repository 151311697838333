import { useWorksheetHandler } from "@/globals/helpers/worksheetHandler";
import { ref, watch, computed } from "vue";
import { getIcon } from "@/helpers/api";

type TInputButton = { src: string; route: string; name: string, params?: any, routeName: string };

const useOpenInput = () => {
    const wh = useWorksheetHandler();
    const { instance: actInstance } = wh.$.activity;

    const btnRoutes = {
      photo: "worksheet-take-photos",
      video: "worksheet-take-videos",
      audio: "worksheet-take-audios",
      document: "worksheet-upload-document",
      pdf: "worksheet-upload-pdf",
      expand: "text-editor",
    };
    const btnRouteNames = {
      photo: "WorksheetTakePhotos",
      video: "worksheetTakeVideos",
      audio: "worksheetRecordAudios",
      document: "worksheetUploadDocuments",
      pdf: "worksheetUploadDocuments",
      expand: "WorksheetWriteText",
    };

    const audioBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.audio,
      name: "audio",
      routeName: btnRouteNames.audio,
    });
    const documentBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.document,
      name: "document",
      routeName: btnRouteNames.document,
    });
    const photoBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.photo,
      name: "photo",
      routeName: btnRouteNames.photo,
    });
    const videoBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.video,
      name: "video",
      routeName: btnRouteNames.video,
    });
    const pdfBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.pdf,
      name: "pdf",
      routeName: btnRouteNames.pdf,
    });
    const textBtn = ref<TInputButton | undefined>({
      src: "",
      route: btnRoutes.expand,
      name: "text",
      routeName: btnRouteNames.expand,
    });


      watch([wh.$.activity.uuid, wh.$.task.uuid], () => {
        updateMediaButtons();
      });
      
      const getMediaButtons = computed(() => {
        const notUndefinedButtons = [
          photoBtn.value,
          audioBtn.value,
          pdfBtn.value,
          videoBtn.value,
          documentBtn.value,
        ].filter((btn): btn is TInputButton => btn !== undefined);
        return notUndefinedButtons;
      });
      
      async function createBtn(
        type: "audio" | "document" | "photo" | "video" | "expand" | "pdf",
        isEnabled: boolean | undefined
      ): Promise<undefined | TInputButton> {
        if (isEnabled) {
          const resolvedSrc = await getIcon("1602", type);
          return {
            src: resolvedSrc ?? "",
            route: btnRoutes[type],
            name: type === "expand" ? "text" : type,
            routeName: btnRouteNames[type],
          };
        }
      }
      
      async function updateMediaButtons() {
        audioBtn.value = await createBtn(
          "audio",
          actInstance.value?.problem?.open_ended?.audio
        );
        documentBtn.value = await createBtn(
          "document",
          actInstance.value?.problem?.open_ended?.document
        );
        photoBtn.value = await createBtn(
          "photo",
          actInstance.value?.problem?.open_ended?.photo
        );
        videoBtn.value = await createBtn(
          "video",
          actInstance.value?.problem?.open_ended?.video
        );
        pdfBtn.value = await createBtn(
          "pdf",
          actInstance.value?.problem?.open_ended?.pdf
        );
        textBtn.value = await createBtn(
          "expand",
          actInstance.value?.problem?.open_ended?.text
        );
      }
      

    return {
        audioBtn,
        documentBtn,
        photoBtn,
        videoBtn,
        pdfBtn,
        textBtn,
        getMediaButtons,
        createBtn,
        updateMediaButtons,
    }
}

export { useOpenInput, TInputButton }