import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-223241e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { id: "title" }
const _hoisted_3 = { class: "description-wrapper" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "spinner-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_PopUpMask = _resolveComponent("PopUpMask")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_PopUpMask, {
            style: _normalizeStyle(_ctx.titleFont),
            showArrow: false
          }, {
            icon: _withCtx(() => [
              _createElementVNode("img", { src: _ctx.wifiIcon }, null, 8, _hoisted_1)
            ]),
            title: _withCtx(() => [
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.layout?.labels.title), 1)
            ]),
            description: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout?.labels.lines, (line, key) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: key,
                    innerHTML: line
                  }, null, 8, _hoisted_4))
                }), 128))
              ])
            ]),
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ion_spinner, { name: "lines" })
              ])
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}