<template>
    <div class="stars">
        <span v-for="(nth, key) in 5" class="star" :key="key" @click="toggleStarRow(nth)"
            :ref="el => setStarRef(el as HTMLElement)">
            <img :src="nth <= rating ? starSvg : starFilledSvg"/>
        </span>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { getIcon } from '@/helpers/api';

const emit = defineEmits(['button_click', 'update:rating', 'send_click'])

const props = defineProps({
    rating: { type: Number, required: true}
})

const starSvg = ref<string>()
const starFilledSvg = ref<string>()
const refs = ref< Array<HTMLElement> >([])
const setStarRef = (ref?:HTMLElement | undefined | null) => {
    if(ref && ref.firstChild){
        refs.value.push(ref.firstChild as HTMLElement)
    }
}

onMounted(async () => {
    starSvg.value = await getIcon('1614A', 'icon')
    starFilledSvg.value = await getIcon('1614A', 'star')

    if(starSvg.value){
        // const cssUtils = new CssUtils(starSvg.value)
        // starSvg.value = cssUtils.removeSvgAttribute('fill')
        //                     .addSvgAttribute('fill', 'var(--milage-color-primary)')
        //                     .removeSvgAttribute('width')
        //                     .removeSvgAttribute('height')
        //                     .build()
    }

})


function toggleStarRow(nth:number){
    if(props.rating === nth){
        cleanStarRow('#969696')
        emit('update:rating', 0)
        return
    }
    emit('update:rating', nth)

    cleanStarRow('#969696')
    while(nth > 0){
        setStarColor(refs.value[nth-1], '#00ACDF')
        nth = nth-1
    }
}
function setStarColor(star:HTMLElement, color:string){
    const children = star.children;

    star.setAttribute("fill", color)
    for(let i = 0; i < children.length; i++){
        const c: HTMLElement = children.item(i) as HTMLElement
        c.setAttribute("fill", color)
    }
}
function cleanStarRow(color:string){
    refs.value.forEach((star: HTMLElement) => {
        setStarColor(star, color)
    })
}

const getStarRow = computed(() => false)

/*

<svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>star</title> 
    <path d="M3.488 13.184l6.272 6.112-1.472 8.608 7.712-4.064 7.712 4.064-1.472-8.608 6.272-6.112-8.64-1.248-3.872-7.808-3.872 7.808z"></path> </g></svg>

*/
</script>


<style scoped>
.stars{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
.star{
    height: 100%;
    aspect-ratio: 1 / 1;
}
img{
    width: 100%;
}
</style>