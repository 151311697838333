/**
 * File Viewer Type Determination Module
 *
 * This module is designed to determine the appropriate viewer type for different file formats
 * based on their MIME type or file extension. It supports a range of common file types, including
 * images, videos, audio files, PDFs, and various other formats. The determination process involves
 * mapping given file types to one of several predefined viewer categories: 'image', 'video', 
 * 'audio', 'pdf', or 'other'. This functionality facilitates the selection of suitable viewer 
 * components or handling logic within web applications, particularly when dealing with files 
 * dynamically loaded or uploaded by users.
 */


type Viewer = "image" | "video" | "audio" | "pdf" | "other";

function normalizeType(type: string): string {
  return type.toLowerCase();
}

function isPdf(type: string): boolean {
  const normalizedType = normalizeType(type);
  return normalizedType.includes("pdf");
}

function isImage(type: string): boolean {
  const normalizedType = normalizeType(type);
  return normalizedType.includes("image");
}

/// 'webm' has not been added to the list of video types because it can be both audio and video
function isVideo(type: string): boolean {
  const normalizedType = normalizeType(type);
  return normalizedType.includes("video")
}

function isAudio(type: string): boolean {
  const normalizedType = normalizeType(type);
  return normalizedType.includes("audio");
}

function isElse(type: string): boolean {
  const normalizedType = normalizeType(type);
  return normalizedType.includes("application");
}

/**
 * 
 * @param type Can be either a file extension or a MIME type
 * @returns The viewer type for the given file type, or null if the type is not recognized
 */
function getViewerType(type?: string): Viewer | null {
  if(!type) return null;
  if (isPdf(type)) return "pdf";
  if (isImage(type)) return "image";
  if (isVideo(type)) return "video";
  if (isAudio(type)) return "audio";
  if (isElse(type)) return "other";
  return null;
}

export { getViewerType, isPdf, isImage, isVideo, isAudio, isElse, Viewer };
