import { Drivers, Storage } from "@ionic/storage";

export type Stringified<T> = string & { __type: T };

export class ionicStorageClass<T = any> {
  #storage;
  #lastUpdate = 0;

  constructor(name: string, storeName: string) {
    this.#storage = new Storage({
      name: name,
      storeName: storeName,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    });
    this.initializeDatabase(this.#storage); //.then( () => this.setLastUpdate() )
    console.log("IonicStorageClass " + name + " initialized!");
  }

  protected async setDatabaseItem(key: string, value: Stringified<T> | null) {
    await this.#storage.set(key, value);
    return this;
  }

  protected async getDatabaseItem(key: string): Promise<Stringified<T> | null> {
    const value: Stringified<T> | null = await this.#storage.get(key);
    return value;
  }

  protected async initializeDatabase(database: Storage) {
    await database.create();
  }

  protected async setLastUpdate(date: number) {
    this.#lastUpdate = date;
    await this.#storage.set("last-update", this.#lastUpdate);
  }

  protected async getLastUpdate() {
    const lastUp = await this.#storage
      .get("last-update")
      .then((r) => {
        this.#lastUpdate = r;
        return r;
      })
      .catch(() => {
        this.setLastUpdate(0);
        return this.#lastUpdate;
      });
    console.log("IionicStorage LastUpdate", lastUp);
    return lastUp;
  }
}
