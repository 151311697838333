<template>
    <ion-page>  
        <ion-content>
            <ModalMessage
                :icon="icon"
                :title="title"
                :button="{textContent: button, danger: false}"
                :description="description"
                @button-click="routeAway()"
            />
      </ion-content>
    </ion-page>
</template>


<script setup lang="ts">
import { ref, onBeforeMount, watch } from 'vue'
import { IonContent, IonPage } from "@ionic/vue";
import router from '@/router';
import { getComputedPageLayout, getIcon, IPageLayout } from '@/helpers/api';
import ModalMessage from '@/globals/components/ModalMessage.vue';
import { useModalManager } from '@/modules/a.worksheets/services/modalManager';

const props = defineProps({
    screenId: { type: String, required: true }
})

interface _062X extends IPageLayout {
    labels: {
        title: string,
        lines: Array<string>
        button: string
    }
    icons: {icon: string}
    commands: {
        router: string
    }
}

const title = ref('')
const button = ref('')
const icon = ref<string>()
const description = ref< Array<string> >([])
const router_path = ref('')

const layout = getComputedPageLayout<_062X>(props.screenId)
onBeforeMount(async () => {
    icon.value = await getIcon(props.screenId, 'icon')
})

watch(layout, l => {
    if(l){
        title.value = l.labels.title
        description.value = l.labels.lines
        button.value = l.labels.button
        router_path.value = l.commands.router
    }
}, {immediate: true})


const modalManager = useModalManager()
function routeAway(){
    modalManager.close()
    if(router_path.value) router.push(router_path.value)
}

</script>

<style scoped>
ion-content::part(background){
    background-color: transparent;
}
</style>

<style>
#worksheet-ion-page.ion-page-hidden.ion-page[aria-hidden="true"]{
    display: block !important;
}
</style>