<template>
    <ion-page>
        <ion-content>
            <div class="pop-up-message-wrapper"
            v-if="layout">
                <pop-up-mask>

                    <template #icon>
                        <img :src="icon" />
                    </template>

                    <template #title>
                        <h2 id="title"> {{ layout.labels.title }} </h2>
                    </template>

                    <template #description>
                        <div class="description-wrapper">
                            <span v-for="(line, key) in layout.labels.lines" :key="key" v-html="line">
                            </span>
                        </div>
                    </template>

                    <template #button>
                        <div class="wks-open-input-wrapper">
                            <WksOpenInputOneFile
                                :media-button="getMediaButtons"
                                :placeholder="layout.helps.msg"
                                @set-media="catchMedia"
                            />
                        </div>
                    </template>
                </pop-up-mask>
            </div>
        </ion-content>
    </ion-page>
</template>


<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted, watch } from 'vue'
import { IonContent, IonPage, alertController, toastController } from "@ionic/vue";
import PopUpMask from '@/modules/a.worksheets/views/components/feedback/PopUpMask.vue'
import { getComputedPageLayout } from '@/helpers/api';
import { getIcon } from '@/helpers/api';
import { SolutionData, useWorksheetHandler } from '@/globals/helpers/worksheetHandler';
import { worksheetService } from '@/modules/a.worksheets/services/worksheet.service';
import { Wks_Modals, useModalManager } from '@/modules/a.worksheets/services/modalManager';
import WksOpenInput from "@/modules/a.worksheets/views/components/footer/WksOpenInput.vue";
import { useOpenInput } from '@/modules/a.worksheets/helpers/wksOpenInput';
import { spinnerManager } from '@/globals/pages/spinnerControl';
import WksOpenInputOneFile from '@/modules/a.worksheets/views/components/footer/WksOpenInputOneFile.vue'

interface _1615A {
    labels: {
        title: string,
        lines: Array<string>,
    },
    helps: {
        msg: string,
    },
    errors: {
        maxSizeMBytes: string,
        maxDuration: string
    },
    commands: {
        audio: boolean,
        document: boolean,
        photo: boolean,
        video: boolean,
        pdf: boolean,
        text: boolean,
        maxNumFiles: number,
        maxSizeMBytes: number,
        maxDuration: number
    }
}

const mediaButton = ref<Array<any>>([])
const { updateMediaButtons, getMediaButtons, textBtn } = useOpenInput();

const layout = getComputedPageLayout<_1615A>('1615A')
const icon = ref<string>()
onBeforeMount(async () => {
    icon.value = await getIcon('1615A', 'icon')
    await updateMediaButtons();
})

async function createBtn(type: 'audio' | 'document' | 'photo' | 'video' | 'expand' | 'pdf', isEnabled: boolean | undefined): Promise<undefined | any> {
  if (isEnabled) {
    const resolvedSrc = await getIcon("1602", type)
    return {
      src: resolvedSrc ?? '',
      route: btnRoutes[type],
      name: type === 'expand' ? 'text' : type
    }
  }
}

const btnRoutes = {
  photo: 'worksheet-take-photos',
  video: 'worksheet-take-videos',
  audio: 'worksheet-take-audios',
  document: 'worksheet-upload-document',
  pdf: 'worksheet-upload-pdf',
  expand: 'text-editor',
}

const wh = useWorksheetHandler()
async function catchMedia(data: string | File[], type: SolutionData){
    const problemUUID = wh.$.activity.problem.value?.uuid
    if(problemUUID){
        if(Array.isArray(data) && layout.value){    
            if(isGreaterThen(data[0], layout.value.commands.maxSizeMBytes * 1024 * 1024)){
                const toast = await toastController.create({
                    message: layout.value.errors.maxSizeMBytes + layout.value.commands.maxSizeMBytes,
                    duration: 2500,
                    position: "top"
                })
                toast.present();
                return;
                /// This condition has not been tested properly since only photo and pdf is allowed yet
            }else if(data[0].type.includes('audio') && await greaterDuration(data[0], layout.value.commands.maxDuration)){
                const toast = await toastController.create({
                    message: layout.value.errors.maxDuration + layout.value.commands.maxDuration,
                    duration: 2500,
                    position: "top"
                })
                toast.present()
                return;
            }
        }

        spinnerManager.registerTask("sending feedback")
        await sendFeedback(wh.wksUUID.value, problemUUID, data, type)
        spinnerManager.unregisterTask("sending feedback")
    }
}

function isGreaterThen(file: File, size: number): boolean {
    if(file.size > size){
        return true
    }else{
        return false
    }
}
async function greaterDuration(file: File, maxDuration: number): Promise<boolean> {
    const duration = await getAudioDuration(URL.createObjectURL(file))
    if(duration > maxDuration){
        return true
    }else{
        return false
    }
}

async function getAudioDuration(url: string): Promise<number> {
    return await new Promise<number>((resolve, reject) => {
      const audio = new Audio(url);
      // Set the preload attribute to 'metadata'
      audio.preload = "metadata";

      // Attach the loadedmetadata event listener
      audio.addEventListener("loadedmetadata", () => {
        resolve(audio.duration);
      });
      // Attach an error event listener
      audio.addEventListener("error", () => {
        reject(new Error("Failed to load audio metadata"));
      });
    });
  }

const modalManager = useModalManager()
async function sendFeedback(user_wks_status: string, problem_uuid: string, data: string | File[], type: SolutionData){
    spinnerManager.registerTask("sendFeedback");
    let stringVal = ''
    let fileArr: Array<File> = []

    if(typeof data === 'string'){
        stringVal = data
    }else{
        fileArr = data
    }
    const response = await worksheetService.postFoundError(user_wks_status, problem_uuid, stringVal, fileArr)

    if(response.status === 200){
        modalManager.open(Wks_Modals.PROBLEM_ERROR_FOUND_THANKS)
    }
    spinnerManager.unregisterTask("sendFeedback");
}

</script>

<style scoped>
.pop-up-message-wrapper{
    width: 100%;
    height: 100%;
}

ion-content::part(background) {
    background-color: transparent;
}

#title{
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    text-transform: var(--title-font-transform);
    font-weight: var(--title-font-weight);
    text-align: center;
}
.description-wrapper{
    font-family: var(--description-font-family);
    font-size: var(--description-font-size);
    text-transform: var(--description-font-transform);
    font-weight: var(--description-font-weight); 
    color: var(--milage-color-default-body);
}
.description-wrapper span{
    display: block;
    text-align: center;
}
img{
    width: 100%;
}
.star-wrapper{
    margin-top: 5%;
    height: 4vh;
}
.wks-open-input-wrapper{
    width: 100%;
}
</style>

<style>
#worksheet-ion-page.ion-page-hidden.ion-page[aria-hidden="true"] {
    display: block;
}
</style>

