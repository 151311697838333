<template>
    <ion-page>  
        <ion-content>
            <ModalMessage
                v-if="layout"
                :icon=icon
                :title="layout.labels.title"
                :button="{textContent: layout.labels.button, danger: false}"
                :description="layout.labels.lines"
                @button-click="routeAway()"
            />
      </ion-content>
    </ion-page>
</template>


<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted } from 'vue'
import { IonContent, IonPage } from "@ionic/vue";
import { useRoute, useRouter } from 'vue-router';
import ModalMessage from '@/globals/components/ModalMessage.vue';
import router from '@/router';
import { getComputedPageLayout, getIcon, IPageLayout } from '@/helpers/api';
import { useModalManager } from '@/modules/a.worksheets/services/modalManager';


interface _1614B extends IPageLayout {
    labels: {
        title: string,
        lines: Array<string>
        button: string
    }
    icons: {icon: string}
    commands: {
        router: string
    }
}


const icon = ref<string>()
const layout = getComputedPageLayout<_1614B>('1614B')
onBeforeMount(async () => {
    icon.value = await getIcon('1614B', 'icon')
})


const modalManager = useModalManager()
function routeAway(){
    if(layout.value){
        router.push(layout.value.commands.router)
        modalManager.close('btn click')
    }
}

</script>

<style scoped>
ion-content::part(background){
    background-color: transparent;
}
</style>

<style>
#worksheet-ion-page.ion-page-hidden.ion-page[aria-hidden="true"]{
    display: block !important;
}
</style>