import axios from "axios";
import pinia from "@/plugins/piniaStore";
import { ionicStorageClass } from "@/persistentStorage/ionicStorageClass";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";
import { urls } from "@/stores/urls";
import { userTokenStore } from "@/stores/tokenStore";
import { IPageLayoutObj } from "@/stores/pageLayoutsStore";

//Standard user to get app data - pagelayouts and icons - from backend before user get login
//const system = {email:"system@milage.ualg.pt", password:"38e87d32-ae50-11ed-afa1-0242ac120002", language:'EN'};

const url = urls();

class LabelsDataStoreApi extends ionicStorageClass {
  token = { status: false, access: "", refresh: "" };
  constructor(name: string, storeName: string) {
    super(name, storeName);
  }
  //-------------------------------------
  // Download fonts, save to DB and to Pinia-store EXCEPT if error then load from store
  async downloadAllFonts() {
    const token = userTokenStore();
    try {
      const headers = {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token.access}`,
      };
      const response = await axios.get(`${url.baseURL}fonts/`, {
        headers: headers,
      });
      const data = response.data;
      //console.log("LOADING-FONTS", data);
      console.log("FONTS-LOADED");
      await this.setDatabaseItem("fonts", data);
      return data;
    } catch {
      console.log("ERROR-FONTS loaded into Pinea (only)");
      const data = await this.getDatabaseItem("fonts");
      return data;
    }
  }
  async getLocalFonts(): Promise<any> {
    const data = await this.getDatabaseItem("fonts");
    return data;
  }

  //-------------------------------------
  // Download pageLayoutJSON, save to DB
  async downloadPageLayoutsJSON2DB(lang: string) {
    const token = userTokenStore();
    let createOrUpdateIcons = true;
    const createOrUpdateScreens = true;
    try {
      const headers = {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token.access}`,
      };
      const response = await axios.get(
        `${url.baseURL}page-layouts/?language=${lang}`,
        { headers: headers }
      );
      const data = response.data;

      console.log("PAGE-LAYOUTS LOADED", data);

      const data0 = (await this.getDatabaseItem("icons")) as any;
      if (data0) {
        if (data0["last_update"] == data["last_update"]) {
          createOrUpdateIcons = false;
          console.log("Page-layouts will not be update")
        }
      }
      if (createOrUpdateScreens) {
        await this.setDatabaseItem(lang, data);
        console.log("Page-layouts loaded: lang=", lang);
      }
      return { data: data, createOrUpdate: createOrUpdateScreens };
    } catch {
      console.log("ERROR-LOADING-PAGE-LAYOUTS");
      return { data: "", createOrUpdate: true };
    }
  }

  async getPageLayoutsJSON2DB(lang: string): Promise<{
    data: IPageLayoutObj[];
    last_update: string;
  }> {
    //const data = (await this.getDatabaseItem("icons")) as any;
    const data = await this.getDatabaseItem(lang) as any;
    return data;
  }
  //-----------------------------------------------
  // Download-Icons
  //-------------------------------------
  // JSON
  async downloadIconsJSON(): Promise<{ data: any; createOrUpdate: boolean }> {
    const token = userTokenStore();
    let createOrUpdate = true;
    try {
      const headers = {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token.access}`,
      };
      const response = await axios.get(`${url.baseURL}icons/`, {
        headers: headers,
      });
      const data = response.data;
      const data0 = (await this.getDatabaseItem("icons")) as any;
      if (data0) {
        if (data0["last_update"] == data["last_update"]) {
          createOrUpdate = false;
        }
      }
      if (createOrUpdate) {
        this.setDatabaseItem("icons", data);
        console.log("IconsJSON loaded");
      }
      return { data: data, createOrUpdate: createOrUpdate };
    } catch {
      console.log("ERROR-loading-icons-JSON: Will use from localStorage");
      return { data: "", createOrUpdate: true };
    }
  }
  async getLocalIconsJSON(): Promise<{ data: any; createOrUpdate: boolean }> {
    const data = await this.getDatabaseItem("icons");
    return {
      createOrUpdate: true,
      data: data,
    };
  }
  //---------------------------------------
}

export const labelsDataDB = new LabelsDataStoreApi("labels", "labels-db");
