<template>
  <ion-page>
    <ion-content>
      <ModalMessage
        v-if="layout"
        :icon="icon"
        :title="layout.labels.title"
        :button="{ textContent: layout.labels.button, danger: true }"
        @button-click="closeWks"
      />
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted, PropType } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
import { IPageLayout, getComputedPageLayout, getIcon } from "@/helpers/api";
import ModalMessage from "@/globals/components/ModalMessage.vue";
import { useModalManager } from "@/modules/a.worksheets/services/modalManager";
import router from "@/router";
import { useRoute } from "vue-router";
import { useWorksheetData } from "@/modules/a.worksheets/stores/worksheetDataStore";
import {
  EvaluationScale,
  Reviewer,
  useUserAnswersData,
} from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import { useWorksheetHandler } from "@/globals/helpers/worksheetHandler";
import { useOfflineAnswerUtils } from "@/modules/k.offlineMode/1.worksheets/helpers/useAnswerUtils";

// const props = defineProps({
//   missingSelfReview: { type: Object as PropType<{}>}
// })
interface _1612 extends IPageLayout {
  labels: {
    title: string;
    button: string;
  };
  icons: {
    icon: string;
    button: Array<string>;
  };
}

const icon = ref<string>();
const layout = getComputedPageLayout<_1612>("1612");
onBeforeMount(async () => {
  icon.value = await getIcon("1612", "icon");
});

const worksheetData = useWorksheetData();
const wh = useWorksheetHandler();
const answerStore = useUserAnswersData();
const modalManager = useModalManager();
const offlineAnswerUtils = useOfflineAnswerUtils();
const route = useRoute();
async function closeWks() {
  if (route.path === "/wks-solve" || route.path === "/worksheet") {
    router.push("/learn");
  } else {
    router.push("/offline-mode");
  }
  modalManager.close("leave");
}

function includeZeroPoints() {
  answerStore.answers.forEach((a) => {
    const open = a.answer?.open_ended;
    if (open && open.answer_assessment.length === 0) {
      console.log("including zero points: ", open);

      open.answer_assessment.push({
        who_reviewed: Reviewer.SELF,
        evaluation_scale: EvaluationScale.QUANTITATIVE,
        grade: "0",
        suffix: "points",
      });
    }
  });
}
</script>

<style scoped>
ion-content::part(background) {
  background-color: transparent;
}
</style>

<style>
#worksheet-ion-page.ion-page-hidden.ion-page[aria-hidden="true"] {
  display: block !important;
}
</style>
