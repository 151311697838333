<template>
  <div
    ref="rootRef"
    class="vue-zoomer"
    :style="{ backgroundColor: backgroundColor }"
    @mousewheel.stop="onMouseWheel"
    @DOMMouseScroll.stop="onMouseWheel"
    @mousedown.stop="onMouseDown"
    @mouseup.stop="onMouseUp"
    @mousemove.stop="onMouseMove"
    @touchstart.stop="onTouchStart"
    @touchend.stop="onTouchEnd"
    @touchmove.stop="onTouchMove"
  >
    <div class="zoomer" :style="wrapperStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import _debounce from "./debounce.min.js";
import TapDetector from "./TapDetector";

import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  watch,
} from "vue";

const props = defineProps({
  minScale: { type: Number, default: 1 },
  maxScale: { type: Number, default: 5 },
  zoomed: { type: Boolean, default: false },
  resetTrigger: { type: Number, default: 1e5 },
  aspectRatio: { type: Number, default: 1 },
  backgroundColor: { type: String, default: "transparent" },
  pivot: { type: String, default: "cursor" }, // other options: image-center
  zoomingElastic: { type: Boolean, default: true },
  limitTranslation: { type: Boolean, default: true },
  doubleClickToZoom: { type: Boolean, default: true },
  mouseWheelToZoom: { type: Boolean, default: true },
  rotation: { type: Number, default: 0 },
});

const emit = defineEmits(["update:zoomed", "swipe"]);

const containerWidth = ref<number>(1);
const containerHeight = ref<number>(1);
const containerLeft = ref<number>(0);
const containerTop = ref<number>(0);

const translateX = ref<number>(0);
const animTranslateX = ref<number>(0);
const translateY = ref<number>(0);
const animTranslateY = ref<number>(0);
const scale = ref<number>(1);
const animScale = ref<number>(1);

// Mouse states
const lastFullWheelTime = ref<number>(0);
const lastWheelTime = ref(0);
const lastWheelDirection = ref<"y" | "x">("y");
const isPointerDown = ref<boolean>(false);
const pointerPosX = ref<number>(-1);
const pointerPosY = ref<number>(-1);
const twoFingerInitDist = ref<number>(0);
const panLocked = ref<boolean>(true);
// Others
const raf = ref<number | null>(null);
const tapDetector = ref<TapDetector | null>(null);

const rootRef = shallowRef<HTMLDivElement | null>(null);

const wrapperStyle = computed(() => {
  // console.log("containerWidth.value: ", containerWidth.value); //is set to 100px incorrectly
  // console.log("animTranslateX.value: ", animTranslateX.value);
  const translateX = containerWidth.value * animTranslateX.value;
  const translateY = containerHeight.value * animTranslateY.value;
  return {
    transform: [
      `translate(${translateX}px, ${translateY}px)`,
      `scale(${animScale.value})`,
      `rotate(${props.rotation}deg)`,
    ].join(" "),
  };
});

watch(scale, (val) => {
  if (val !== 1) {
    emit("update:zoomed", true);
    panLocked.value = false;
  }
});

onBeforeMount(() => {
  watchOnce(rootRef,() => {
    setTimeout(onWindowResize, 250);
    setTimeout(onWindowResize, 500);
  })
})

onMounted(() => {
  tapDetector.value = new TapDetector();
  tapDetector.value.attach(rootRef.value);
  if (props.doubleClickToZoom) {
    tapDetector.value.onDoubleTap(onDoubleTap);
  }

  window.addEventListener("resize", _debounce(() => requestAnimationFrame(onWindowResize), 150)); // wait in order for the window to recalculate element sizes

  onWindowResize();
  refreshContainerPos();
  loop();
});

onBeforeUnmount(() => {
  tapDetector.value?.detach(rootRef.value);
  window.removeEventListener("resize", onWindowResize);
  if (typeof raf.value === "number") {
    window.cancelAnimationFrame(raf.value);
  }
});

function reset() {
  scale.value = 1;
  panLocked.value = true;
  translateX.value = 0;
  translateY.value = 0;
}

function zoomIn(scale = 2) {
  tryToScale(scale);
  onInteractionEnd();
}
function zoomOut(scale = 0.5) {
  tryToScale(scale);
  onInteractionEnd();
}


function tryToScale(scaleDelta: number) {
  let newScale = scale.value * scaleDelta;
  if (props.zoomingElastic) {
    // damping
    if (newScale < props.minScale || newScale > props.maxScale) {
      let log = Math.log2(scaleDelta);
      log *= 0.2;
      scaleDelta = Math.pow(2, log);
      newScale = scale.value * scaleDelta;
    }
  } else {
    if (newScale < props.minScale) newScale = props.minScale;
    else if (newScale > props.maxScale) newScale = props.maxScale;
  }
  scaleDelta = newScale / scale.value;
  scale.value = newScale;
  if (props.pivot !== "image-center") {
    const normMousePosX =
      (pointerPosX.value - containerLeft.value) / containerWidth.value;

    const normMousePosY =
      (pointerPosY.value - containerTop.value) / containerHeight.value;
    translateX.value =
      (0.5 + translateX.value - normMousePosX) * scaleDelta +
      normMousePosX -
      0.5;
    translateY.value =
      (0.5 + translateY.value - normMousePosY) * scaleDelta +
      normMousePosY -
      0.5;
  }
}

function setPointerPosCenter() {
  pointerPosX.value = containerLeft.value + containerWidth.value / 2;
  pointerPosY.value = containerTop.value + containerHeight.value / 2;
}

function onPointerMove(newMousePosX: number, newMousePosY: number) {
  if (isPointerDown.value) {
    const pixelDeltaX = newMousePosX - pointerPosX.value;
    const pixelDeltaY = newMousePosY - pointerPosY.value;
    // console.log('pixelDeltaX, pixelDeltaY', pixelDeltaX, pixelDeltaY)
    if (!panLocked.value) {
      translateX.value += pixelDeltaX / containerWidth.value;
      translateY.value += pixelDeltaY / containerHeight.value;
    }
  }
  pointerPosX.value = newMousePosX;
  pointerPosY.value = newMousePosY;
}

const onInteractionEnd = _debounce(function () {
  limit();
  panLocked.value = scale.value === 1;
  emit("update:zoomed", !panLocked.value);
}, 100);

function limit() {
  // scale
  if (scale.value < props.minScale) {
    scale.value = props.minScale;
    // FIXME this sometimes will not reset when pinching in
    // tryToScale(minScale / scale)
  } else if (scale.value > props.maxScale) {
    tryToScale(props.maxScale / scale.value);
  }
  // translate
  if (props.limitTranslation) {
    const translateLimit = calcTranslateLimit();
    if (Math.abs(translateX.value) > translateLimit.x) {
      translateX.value *= translateLimit.x / Math.abs(translateX.value);
    }
    if (Math.abs(translateY.value) > translateLimit.y) {
      translateY.value *= translateLimit.y / Math.abs(translateY.value);
    }
  }
}

function calcTranslateLimit() {
  if (getMarginDirection() === "y") {
    const imageToContainerRatio =
      containerWidth.value / props.aspectRatio / containerHeight.value;
    let translateLimitY = (scale.value * imageToContainerRatio - 1) / 2;
    if (translateLimitY < 0) translateLimitY = 0;
    return {
      x: (scale.value - 1) / 2,
      y: translateLimitY,
    };
  } else {
    const imageToContainerRatio =
      (containerHeight.value * props.aspectRatio) / containerWidth.value;
    let translateLimitX = (scale.value * imageToContainerRatio - 1) / 2;
    if (translateLimitX < 0) translateLimitX = 0;
    return {
      x: translateLimitX,
      y: (scale.value - 1) / 2,
    };
  }
}

function getMarginDirection() {
  const containerRatio = containerWidth.value / containerHeight.value;
  return containerRatio > props.aspectRatio ? "x" : "y";
}

function onDoubleTap(ev: any) {
  if (scale.value === 1) {
    if (ev.clientX > 0) {
      pointerPosX.value = ev.clientX;
      pointerPosY.value = ev.clientY;
    }
    tryToScale(Math.min(3, props.maxScale));
  } else {
    reset();
  }
  onInteractionEnd();
}


function onWindowResize() {
  if (rootRef.value !== null) {
    const styles = window.getComputedStyle(rootRef.value);

    containerWidth.value = parseFloat(styles.width);
    containerHeight.value = parseFloat(styles.height);
  }
    setPointerPosCenter();
    reset()
}

function refreshContainerPos() {
  if (rootRef.value !== null) {
    const rect = rootRef.value?.getBoundingClientRect();
    containerLeft.value = rect.left;
    containerTop.value = rect.top;
  }
}

function loop() {
  animScale.value = gainOn(animScale.value, scale.value);
  animTranslateX.value = gainOn(animTranslateX.value, translateX.value);
  animTranslateY.value = gainOn(animTranslateY.value, translateY.value);
  raf.value = window.requestAnimationFrame(loop);

  // console.log(`animTranslateX: ${animTranslateX.value}, animTranslateY: ${animTranslateY.value}`)
  // console.log(
  //   `translateX: ${translateX.value}, translateY: ${translateY.value}`
  // );

  // console.log('loop', raf)
}

function gainOn(from: number, to: number) {
  const delta = (to - from) * 0.3;
  // console.log('gainOn', from, to, from + delta)
  if (Math.abs(delta) > 1e-5) {
    return from + delta;
  } else {
    return to;
  }
}

function onMouseWheel(ev: any) {
  if (!props.mouseWheelToZoom) return;

  // prevent is used to stop the page scroll elastic effects
  ev.preventDefault();
  if (ev.detail) ev.wheelDelta = ev.detail * -10;
  const currTime = Date.now();
  if (Math.abs(ev.wheelDelta) === 120) {
    // Throttle the TouchPad pinch on Mac, or it will be too sensitive
    if (currTime - lastFullWheelTime.value > 50) {
      onMouseWheelDo(ev.wheelDelta);
      lastFullWheelTime.value = currTime;
    }
  } else {
    if (currTime - lastWheelTime.value > 50 && typeof ev.deltaX === "number") {
      lastWheelDirection.value =
        ev.detail == 0 && Math.abs(ev.deltaX) > Math.abs(ev.deltaY) ? "x" : "y";
      if (lastWheelDirection.value === "x") {
        emit("swipe", ev.deltaX > 0 ? "left" : "right");
      }
    }
    if (lastWheelDirection.value === "y") {
      onMouseWheelDo(ev.wheelDelta);
    }
  }
  lastWheelTime.value = currTime;
}

function onMouseWheelDo(wheelDelta: number) {
  // Value basis: One mouse wheel (wheelDelta=+-120) means 1.25/0.8 scale.
  const scaleDelta = Math.pow(1.25, wheelDelta / 120);
  tryToScale(scaleDelta);
  onInteractionEnd();
}

function onMouseDown(ev: MouseEvent) {
  refreshContainerPos();
  isPointerDown.value = true;
  // Open the context menu then click other place will skip the mousemove events.
  // This will cause the pointerPosX/Y NOT sync, then we will need to fix it on mousedown event.
  pointerPosX.value = ev.clientX;
  pointerPosY.value = ev.clientY;
  // console.log('onMouseDown', ev)
}

function onMouseUp(ev: MouseEvent) {
  isPointerDown.value = false;
  onInteractionEnd();
}

function onMouseMove(ev: MouseEvent) {
  onPointerMove(ev.clientX, ev.clientY);
  // console.log('onMouseMove client, offset', ev.clientX, ev.clientY)
}

function onTouchStart(ev: TouchEvent) {
  if (ev.touches.length === 1) {
    refreshContainerPos();
    pointerPosX.value = ev.touches[0].clientX;
    pointerPosY.value = ev.touches[0].clientY;
    isPointerDown.value = true;
  } else if (ev.touches.length === 2) {
    isPointerDown.value = true;
    // pos
    pointerPosX.value = (ev.touches[0].clientX + ev.touches[1].clientX) / 2;
    pointerPosY.value = (ev.touches[0].clientY + ev.touches[1].clientY) / 2;
    // dist
    const distX = ev.touches[0].clientX - ev.touches[1].clientX;
    const distY = ev.touches[0].clientY - ev.touches[1].clientY;
    twoFingerInitDist.value = Math.sqrt(distX * distX + distY * distY);
  }
  // console.log('onTouchStart', ev.touches)
}

function onTouchEnd(ev: TouchEvent) {
  if (ev.touches.length === 0) {
    isPointerDown.value = false;
    // Near 1 to set 1
    if (Math.abs(scale.value - 1) < 0.1) scale.value = 1;
    onInteractionEnd();
  } else if (ev.touches.length === 1) {
    pointerPosX.value = ev.touches[0].clientX;
    pointerPosY.value = ev.touches[0].clientY;
  }
}

function onTouchMove(ev: TouchEvent) {
  if (ev.touches.length === 1) {
    onPointerMove(ev.touches[0].clientX, ev.touches[0].clientY);
  } else if (ev.touches.length === 2) {
    // pos
    const newMousePosX = (ev.touches[0].clientX + ev.touches[1].clientX) / 2;
    const newMousePosY = (ev.touches[0].clientY + ev.touches[1].clientY) / 2;
    onPointerMove(newMousePosX, newMousePosY);
    pointerPosX.value = newMousePosX;
    pointerPosY.value = newMousePosY;
    // dist
    const distX = ev.touches[0].clientX - ev.touches[1].clientX;
    const distY = ev.touches[0].clientY - ev.touches[1].clientY;
    const newTwoFingerDist = Math.sqrt(distX * distX + distY * distY);
    tryToScale(newTwoFingerDist / twoFingerInitDist.value);
    twoFingerInitDist.value = newTwoFingerDist;
  }
  // console.log('onTouchMove', pointerPosX, pointerPosY)
}

watch(
  computed(() => props.zoomed),
  (newV, oldV) => {
    if (oldV === true && newV === false) {
      reset()
    }
  }
);


defineExpose({
  reset,
  refresh: refreshContainerPos
})

</script>

<style scoped>
.vue-zoomer {
  overflow: hidden;
}
.zoomer {
  transform-origin: 50% 50%;
  width: 100%;
  height: 100%;
}
.zoomer > img {
  /* remove the 4px gap below the image */
  vertical-align: top;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
}
</style>
