import {
  IFooterLayout,
  IHomepageUserResponse,
  useFooterData,
} from "@/globals/stores/storeFooterData";
import { useDropdownUser } from "@/globals/stores/storeDropdownData";
import { toRefs, watch } from "vue";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { spinnerManager } from "@/globals/pages/spinnerControl";
import dataTreeManager from "@/persistentStorage/wks_storage/dataTreeManager";
import {
  loadOnline,
  loadOffline,
  presentAlert,
} from "@/modules/0.startApp/helpers/loader";
import router from "@/router";
import { useAccountDataUser } from "@/modules/5.account/stores/accountDataStore";
import requestService from "@/services/requests";
import { useFonts, useIcons, usePageLayouts } from "@/stores/pageLayoutsStore";
import { userTokenStore } from "@/stores/tokenStore";

export const useGlobalWatchers = () => {
  const { isNetConnected } = toRefs(useDeviceStatus());
  const accountData = useAccountDataUser();
  const fontsStore = useFonts();
  const pageLayoutsStore = usePageLayouts();
  const deviceStatus = useDeviceStatus();
  const iconStore = useIcons();
  const tokenStore = userTokenStore();

  const footerData = useFooterData();
  const dropdown = useDropdownUser();
  watch(isNetConnected, async (isNet) => {
    console.warn("isNet: ", isNet);
    if (!isNet) {
      spinnerManager.registerTask("offline mode");
      const offlineWksData = await dataTreeManager.loadAndGet(accountData.uuid);
      const keys = offlineWksData.worksheets.keys();

      if (keys && Array.from(keys).length > 0) {
        // await loadOffline(
        //   fontsStore,
        //   pageLayoutsStore,
        //   accountData,
        //   deviceStatus,
        //   iconStore
        // );
        dropdown.setOfflineMode();
        footerData.setOfflineMode();
        router.replace("/offline-mode");
      } else {
        await presentAlert();
      }
      spinnerManager.unregisterTask("offline mode");
    } else {
      spinnerManager.registerTask("online mode");
      
      if (tokenStore.getIsAuth) {
        /// update active footer buttons
        const resp = await requestService.getWithParams<IHomepageUserResponse>(
          "homepage-user",
          { uuid: accountData.uuid }
        );
        const response = resp.data;
        footerData.setHomepageUserFooterData(response);
      }else {
        router.replace("/login");
      }

      spinnerManager.unregisterTask("online mode");
    }
  });
};
