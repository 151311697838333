<template>
  <div class="flex">
    <img data-dropable="true" :src="thumbnail" />
    <audio controls :src="source" :type="type"></audio>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { MimeType } from '@/globals/helpers/media/mimeTypeUtils';

defineProps({
  thumbnail: { type: String, default: (() => undefined) },
  source: { type: String, required: true },
  type: { type: String as PropType<MimeType>}
})

const audio = new Audio();


</script>

<style scoped>
.flex {
  width: 100%;
}

.flex>* {
  width: 100%;
}
</style>

