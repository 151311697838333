
import { Stringified, ionicStorageClass } from "@/persistentStorage/ionicStorageClass";
//import { downloadWriteFile } from "@/helpers/imagesReadWrite";
import { getDir, createFolder } from "@/helpers/imagesReadWrite";
import {readFile, writeFile, blobToBase64} from "@/helpers/filesReadWrite";

import { useIcons } from "@/stores/pageLayoutsStore";

import { iconsFilesDB } from  "@/persistentStorage/iconsStorageFiles";


import { urls } from "@/stores/urls";
const url = urls();

//interface iconslist { name:string, list:[] }
export interface IIcons{
    screen_id:string,
    icons:object,
    iconsdark:object,
    last_update:string
}

async function createIconsDIR(){
    const dir = await getDir("");
    if (!(dir.find( x => x.name === 'milage_icons'))){ 
        await createFolder("milage_icons")}
}

//-----------------------------------
//Class definition 
class IconsLocalFiles extends ionicStorageClass{
    
    constructor(name:string, storeName:string){
        createIconsDIR();
        super (name, storeName)
    }

    //------------------------

    async getIconInfo(key:string): Promise<{page: string, size: number, type: string, uri: {uri: string}} | null>{
        const info = await this.getDatabaseItem(key);
        return info as {page: string, size: number, type: string, uri: {uri: string}} | null;
      }
  
    //----------
    async readWriteIconsFromPageList(lst:string[]){
        const iconStore = useIcons();
        lst.forEach((element) => {
            const iconsPage = iconStore.getPage(element);
            const icons = Object.entries(iconsPage.icons);
            const iconsdark = Object.entries(iconsPage.iconsdark); 
            //console.log("ICONS", icons)
            const aa = [{name:'icons', list:icons}, {name:'iconsdark', list: iconsdark}]
            aa.forEach( ( item ) => {
                const type = item.name;
                if (item.list.length > 0){
                    item.list.forEach( async ([icon_key, icon_item]) => {
                            const filename = `${element}_${type}_${icon_key}`;
                            const url0 = `${url.getStatic}${icon_item}`;
                            const response = await fetch(url0);
                            const blob = await response.blob();
                            if (blob.type == "image/svg+xml"){
                                new Blob([blob], { type: 'image/png' })
                            }
                            const data64 = await blobToBase64(blob)
                            const uri = await writeFile("milage_icons/"+filename, data64);
                            const id = `${element}_${type}_${icon_key}`;
                            const stringified : Stringified<any> = JSON.stringify({page:element, type:blob.type, size:blob.size, uri:uri}) as Stringified<any>;
                            this.setDatabaseItem(id, stringified);
                            iconsFilesDB.saveFile(id, data64 as string)
                    })
                }
            })
        })
    }
    //----------
/*
    async writeIconsFiles(iconsSrc:IIcons){
        const icons = Object.entries(iconsSrc.icons);
        const iconsdark = Object.entries(iconsSrc.iconsdark); 
        console.log("ICONS", icons)
        const aa = [{name:'icons', list:icons}, {name:'iconsdark', list: iconsdark}]
        aa.forEach( ( item ) => {
            const type = item.name;
            if (item.list.length > 0){
                item.list.forEach( async ([icon_key, icon_item]) => {
                        const filename = `${iconsSrc.screen_id}_${type}_${icon_key}`;
                        const url0 = `${url.getStatic}${icon_item}`;
                        //console.log("ICONS LOAD",filename, url0)
                        const response = await fetch(url0)//.then((resp) => {resp});//.catch((err)=>console.log("fetch error",page.screen_id));
                        //console.log("FETCH", response);
                        const blob = await response.blob();
                        //console.log("BLOB", blob);
                        const uri = await downloadWriteFile("milage_icons/"+filename, blob);
                        //console.log("URI", uri);
                        const id = `${iconsSrc.screen_id}_${type}_${icon_key}`;
                        this.setDatabaseItem(id, {page:iconsSrc.screen_id, type:blob.type, size:blob.size});
                    })    
            }
        })
    } */
    //----------
    }

export const iconsLocalFiles = new IconsLocalFiles("icons","icons-db");
