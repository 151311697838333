import {
    reactive, readonly, InjectionKey, provide, inject, ComponentPublicInstance, Component,
    ExtractPropTypes, VNodeProps, AllowedComponentProps, ComponentOptionsMixin, watch
} from 'vue';
import { ComponentRef } from '@ionic/core'
import { ModalOptions, modalController } from '@ionic/vue';
import router from '@/router';
import PointRulesModal from '@/modules/a.worksheets/views/modules/modals/PointRulesModal.vue';
import WksExtraInfoModal from '@/modules/a.worksheets/views/components/WksExtraInfoModal.vue';
import CompletedModal from '@/modules/a.worksheets/views/modules/modals/CompletedModal.vue'
import NewSolutionModal from '@/modules/a.worksheets/views/modules/modals/NewSolutionModal.vue';
import NewSolutionThanksModal from '@/modules/a.worksheets/views/modules/modals/NewSolutionThanksModal.vue'
import FeedbackModal from '@/modules/a.worksheets/views/modules/modals/FeedbackModal.vue'
import FeedbackSuccessModal from '@/modules/a.worksheets/views/modules/modals/FeedbackSuccessModal.vue' 
import LeaveModal from '@/modules/a.worksheets/views/modules/modals/LeaveModal.vue' 
import FoundErrorModal from '@/modules/a.worksheets/views/modules/modals/FoundErrorModal.vue' 
import FoundErrorThanksModal from '@/modules/a.worksheets/views/modules/modals/FoundErrorThanksModal.vue' 

export enum Wks_Modals {
    POINT_RULES = 'point-rules',
    LEAVE = 'leave',
    FEEDBACK = 'feedback',
    FEEDBACK_SUCCESS = 'feedback-success',
    PROBLEM_ERROR_FOUND = 'problem-error-found',
    PROBLEM_ERROR_FOUND_THANKS = 'problem-error-found-thanks',
    NEW_SOLUTION = 'new-solution',
    NEW_SOLUTION_THANKS = 'new-solution-thanks',
    COMPLETED = 'completed',
    EXTRA_INFO = 'extra-info'
}


type ExtractOptionalPropTypes<T extends ComponentOptionsMixin> = {
    [K in keyof T['props']as T['props'][K] extends { required: true } ? never : K]: T['props'][K]
};

type ExtractRequiredPropTypes<T extends ComponentOptionsMixin> = {
    [K in keyof T['props']as T['props'][K] extends { required: true } ? K : never]: T['props'][K]
};

type ExtractProps<T extends ComponentOptionsMixin> = Partial<ExtractOptionalPropTypes<T>> & ExtractRequiredPropTypes<T>;

interface IModalManager {
    close: (msg?: string) => void
    open: (modal: Wks_Modals, args?: ModalOptions) => void,
}

class ModalManager {
    private controller: typeof modalController
    modal: HTMLIonModalElement | undefined;

    constructor() {
        this.controller = modalController
       watch(router.currentRoute, () => {
            this.close('route changed')
       }) 
    }

    async close(msg?: string) {
        if (this.modal) {
            await this.modal.dismiss(null, msg)
        }
    }
    async open<T extends ComponentPublicInstance>(modal: Wks_Modals, args?: any) {
        switch (modal) {
            case Wks_Modals.POINT_RULES:
                await this._open<T>(PointRulesModal, args);
                break;
            case Wks_Modals.EXTRA_INFO:
                await this._open<T>(WksExtraInfoModal, args, ['wks-media-modal']);
                break;
            case Wks_Modals.COMPLETED:
                await this._open(CompletedModal, args);
                break;
            case Wks_Modals.FEEDBACK:
                await this._open(FeedbackModal, args);
                break;
            case Wks_Modals.FEEDBACK_SUCCESS:
                await this._open(FeedbackSuccessModal, args);
                break;
            case Wks_Modals.LEAVE:
                await this._open(LeaveModal, args);
                break;
            case Wks_Modals.NEW_SOLUTION:
                await this._open(NewSolutionModal, args);
                break;
            case Wks_Modals.NEW_SOLUTION_THANKS:
                await this._open(NewSolutionThanksModal, args);
                break;
            case Wks_Modals.PROBLEM_ERROR_FOUND:
                await this._open(FoundErrorModal, args);
                break;
            case Wks_Modals.PROBLEM_ERROR_FOUND_THANKS:
                await this._open(FoundErrorThanksModal, args);
                break;
        }
        return this.modal!;
    }
    private async _open<T extends ComponentPublicInstance>(cmp: any, props?: ExtractProps<T>, css?: Array<string>) {
        if (this.modal) {
            this.modal.dismiss(null, 'switch modals')
        }  
        console.log("modalManager props:", props)
        this.modal = await this.controller.create({
            component: cmp,
            componentProps: { ...props },
            cssClass: ['wks-modal', ...(css || [])].join(' ')
        })
        this.modal.present()
    }
}

const manager = new ModalManager();

const ModalManagerKey: InjectionKey<ModalManager> = Symbol('ModalManager');
// Function to provide the Worksheet instance
export function provideModalManager() {
    provide(ModalManagerKey, manager);
}

// Function to inject the Worksheet instance
export function useModalManager() {
    const injectedInstance = inject(ModalManagerKey);
    if (!injectedInstance) {
        throw new Error('No modal-manager instance provided!');
    }
    return injectedInstance;
}