<template>
  <div
    @click="(e) => (!isWeb ? handleClick(e) : null)"
    class="image-viewer"
    ref="imgContainerRef"
  >
    <div class="overflow-hidden">
      <CustomZoomer
        v-if="zoomable && cmpHeight"
        :limitTranslation="false"
        :zoomed="isZoomed"
        @update:zoomed="((v: boolean) => isZoomed = v)"
        ref="vZoomerRef"
        :rotation="rotation"
      >
        <div class="img-container">
          <img :src="source" alt="" srcset="" class="img" @load="loaded" />
        </div>
      </CustomZoomer>
      <img
        v-if="!zoomable || !cmpHeight"
        :src="source"
        alt=""
        srcset=""
        class="img"
        @load="loaded"
        :style="getRotationCss"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, watch, shallowRef, toRefs, computed } from "vue";
import { getIcon } from "@/helpers/api";
import { useDeviceStatus } from "@/stores/deviceStatus";
import CustomZoomer from "@/globals/modules/CustomZoomer/CustomZoomer.vue";

const props = defineProps({
  source: { type: String, required: true },
  zoomable: { type: Boolean, default: true },
  rotation: { type: Number, default: 0 },
});
const vZoomerRef = shallowRef<InstanceType<typeof CustomZoomer> | null>(null);
watch(computed(() => props.rotation), () => vZoomerRef.value?.reset());

const emit = defineEmits<{
  (e: "click", source: string): void;
  (e: "loaded"): void;
}>();


const isZoomed = ref<boolean>(false);
const { isWeb } = toRefs(useDeviceStatus());
const expand = ref<string>();
onBeforeMount(async () => {
  expand.value = await getIcon("1602", "expand");
});

const handleClick = (event: Event) => {
  if (props.zoomable) {
    event.stopPropagation();
  }
  emit("click", props.source);
};

const cmpHeight = ref<string | undefined>();
const imgContainerRef = shallowRef<HTMLElement | null>(null);

const updateHeight = () => {
  if (imgContainerRef.value !== null) {
    const cmpStyles = window.getComputedStyle(imgContainerRef.value);
    cmpHeight.value = cmpStyles.getPropertyValue("height");
  }
};

function loaded() {
  emit("loaded");
  updateHeight();
  vZoomerRef.value?.reset();
}


const getRotationCss = computed(() => {
  return {
    transform: `rotate(${props.rotation}deg)`,
  };
})
</script>
<style scoped>
.image-viewer {
  overflow: hidden;
}

div {
  width: 100%;
  height: 100%;
  position: relative;
}

img {
  max-width: 100%;
  max-height: 100%;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.img-container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;

  padding: 2%;
}

.expand-icon {
  cursor: pointer;
  position: absolute;
  right: 2%;
  top: 0;
  width: 4%;
  z-index: 1;
  transition: all 0.2s;
}

.expand-icon:hover {
  transform: scale(1.1);
}

.web ion-modal .expand-icon {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}
</style>

<style>
ion-modal .image-viewer .img-container img {
  max-width: 100%;
  max-height: 100%;
}
</style>
